import React from "react";
import useInfiniteSearch from "hooks/useInfiniteSearch";
import { fetchStatusBasedOfferings } from "../Codes.api.helpers";
import { reorderListingsBasedOnStatus } from "../../../../features/Listings/utils/reorderListingsBasedOnStatus";
import { offeringStatusToShow } from "../Codes.config";

const statusString = offeringStatusToShow.join(",");

export const useFetchCustomCodeOfferings = () => {
  const {
    data: offeringOptions,
    loading,
    setLoading,
    setError,
    setHasMore,
    pageNumber,
    setData,
    lastElementRef,
  } = useInfiniteSearch();

  React.useEffect(() => {
    setLoading(true);
    setError(false);

    fetchStatusBasedOfferings({ pageNumber, status: statusString })
      .then(({ listings, total_pages }) => {
        setHasMore(total_pages > pageNumber);
        const listData = listings.map((item) => ({
          ...item,
          label: item.title,
          value: item.uuid,
        }));
        setData((prevData) =>
          reorderListingsBasedOnStatus(
            [...prevData, ...listData],
            offeringStatusToShow
          )
        );
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [pageNumber, setData, setError, setHasMore, setLoading]);

  return {
    loading,
    offeringOptions,
    lastElementRef,
  };
};
