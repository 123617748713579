import React from "react";

const NewRank = ({ higher }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect
        width="20"
        height="20"
        rx="10"
        fill="url(#paint0_linear_4740_2846)"
      />
      <mask
        id="mask0_4740_2846"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="12"
        height="12"
      >
        <rect x="4" y="4" width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4740_2846)">
        <path
          d="M5.34957 12.8126C5.24957 12.7126 5.20165 12.5938 5.20582 12.4563C5.20998 12.3188 5.2579 12.2043 5.34957 12.1126L7.98707 9.4376C8.17873 9.24593 8.41623 9.1501 8.69957 9.1501C8.9829 9.1501 9.2204 9.24593 9.41207 9.4376L10.6996 10.7376L13.2996 8.1626H12.4996C12.3579 8.1626 12.2392 8.11468 12.1433 8.01885C12.0475 7.92301 11.9996 7.80426 11.9996 7.6626C11.9996 7.52093 12.0475 7.40218 12.1433 7.30635C12.2392 7.21051 12.3579 7.1626 12.4996 7.1626H14.4996C14.6412 7.1626 14.76 7.21051 14.8558 7.30635C14.9517 7.40218 14.9996 7.52093 14.9996 7.6626V9.6626C14.9996 9.80426 14.9517 9.92301 14.8558 10.0188C14.76 10.1147 14.6412 10.1626 14.4996 10.1626C14.3579 10.1626 14.2392 10.1147 14.1433 10.0188C14.0475 9.92301 13.9996 9.80426 13.9996 9.6626V8.8626L11.4121 11.4501C11.2204 11.6418 10.9829 11.7376 10.6996 11.7376C10.4162 11.7376 10.1787 11.6418 9.98707 11.4501L8.69957 10.1626L6.04957 12.8126C5.9579 12.9043 5.84123 12.9501 5.69957 12.9501C5.5579 12.9501 5.44123 12.9043 5.34957 12.8126Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_4740_2846"
          x1="2.5"
          y1="-6.25"
          x2="25.3125"
          y2="33.4375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={higher ? "#00B779" : "#BF0000"} />
          <stop offset="0.666667" stopColor={higher ? "#00AED5" : "#E9B706"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NewRank;
