import React, { useCallback, useMemo, useState } from "react";
import classnames from "classnames";
import { useListController, useRefresh } from "react-admin";
import CreateRoundedIcon from "@material-ui/icons/CreateRounded";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import useStyles from "./Codes.styles.js";
import { customCodeLabels, getTableConfig } from "./Codes.config";
import { is_empty } from "utils/validations.js";
import ExlyTable from "common/Components/ExlyTable/index.js";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme.js";
import { CodesHeader, EmptyState } from "./components/CodesHeader.jsx";
import { useToggleState } from "utils/hooks/useToggleState.js";
import AddCodeModal from "./components/AddCodeModal";
import {
  DesktopActions,
  MobileActions,
} from "./components/TableFields/TableFields.jsx";
import { updateCustomCode } from "./Codes.api.helpers.js";
import { useNotifications } from "utils/hooks/useNotifications";
import { useHistory } from "react-router";
import { Header } from "common/Components/Header/index.js";
import useHideNavAndAppBar from "hooks/useHideNavAndAppBar.js";
import { logError } from "utils/error.js";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings.js";
import { custom_code_activation_status } from "features/CustomCode/CustomCode.constants.js";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery.js";
import ArchiveIcon from "@material-ui/icons/Archive";
import {
  constants,
  integrationsCards,
} from "../marketing/sales/integrations.constants.js";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import LearnMoreStrip from "features/Common/modules/LearnMore/modules/LearnMoreStrip/LearnMoreStrip";

const {
  ids: { custom_code },
} = constants;
const { learn_more_href, title } = integrationsCards[custom_code];
const layoutProps = {
  paddingDesktop: "0",
  paddingBottom: "68px",
  layoutDesktopMargin: "0px 24px 0 24px",
  noMobileBoxShadow: true,
  showFixedBars: true,
};

const CustomCodes = (props) => {
  const [selectedCode, setSelectedCode] = useState(null);
  const [isCodeModalActive, showCodeModal, hideCodeModal] =
    useToggleState(false);
  const [isMoreActionsActive, showMoreActions, hideMoreActions] =
    useToggleState(false);
  const { notify } = useNotifications();
  const refresh = useRefresh();
  const history = useHistory();

  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles();
  const { filterValues, loaded, data } = useListController(props);

  const isEmpty = useMemo(
    () => loaded && is_empty(data) && is_empty(filterValues),
    [loaded, data, filterValues]
  );

  const footerProps = useMemo(
    () => ({
      primarybtn: customCodeLabels.addButton,
      primaryClick: showCodeModal,
    }),
    [showCodeModal]
  );

  const onCloseModal = useCallback(() => {
    hideCodeModal();
    setSelectedCode(null);
  }, [hideCodeModal, setSelectedCode]);

  const onEdit = useCallback(
    (record) => {
      setSelectedCode(record);
      showCodeModal();
    },
    [showCodeModal]
  );

  const deleteCustomCode = useCallback(() => {
    if (!selectedCode?.uuid) return;

    updateCustomCode({
      code_uuid: selectedCode.uuid,
      updated_values: {
        status: custom_code_activation_status.INACTIVE,
      },
    })
      .then(() => {
        notify("Code has successfully been Deleted!", "success");
        refresh();
        setSelectedCode(null);
      })
      .catch((err) => {
        notify(err?.message || "Failed to delete", "error");
        console.log(err, "Error while deleting code");
      });
  }, [selectedCode]);

  const toggleStatus = () =>
    updateCustomCode({
      code_uuid: selectedCode.uuid,
      updated_values: {
        status:
          selectedCode.status === custom_code_activation_status.ACTIVE
            ? custom_code_activation_status.ARCHIVE
            : custom_code_activation_status.ACTIVE,
      },
    })
      .then(() => {
        hideMoreActions();
        refresh();
      })
      .catch((error) => {
        logError({
          error,
          when: "toggleStatus",
          occuredAt: "src/ui/pages/CustomCodes/Codes.jsx",
        });
        notify(error?.body?.message ?? SOMETHING_WENT_WRONG);
      });

  const moreActions = useMemo(
    () => [
      {
        label: "Edit Custom Code",
        icon: <CreateRoundedIcon />,
        onClick: () => {
          showCodeModal();
          hideMoreActions();
        },
      },
      {
        label: "Delete",
        icon: <DeleteRoundedIcon />,
        onClick: () => {
          deleteCustomCode();
          hideMoreActions();
        },
      },
      ...(!is_empty(selectedCode) &&
      [
        custom_code_activation_status.ACTIVE,
        custom_code_activation_status.ARCHIVE,
      ].includes(selectedCode.status)
        ? [
            {
              label:
                selectedCode.status === custom_code_activation_status.ACTIVE
                  ? "Deactivate"
                  : selectedCode.status ===
                    custom_code_activation_status.ARCHIVE
                  ? "Activate"
                  : null,
              onClick: toggleStatus,
              icon: <ArchiveIcon />,
            },
          ]
        : []),
    ],
    [showCodeModal, deleteCustomCode, hideMoreActions, selectedCode]
  );

  const handleMoreActionsClick = useCallback(
    (record) => {
      setSelectedCode(record);
      showMoreActions();
    },
    [showMoreActions]
  );

  const columnConfig = useMemo(() => {
    return getTableConfig({ onEdit, isDesktop });
  }, [onEdit]);

  const handleBack = () => history.goBack();

  useHideNavAndAppBar("Integrations");

  return (
    <div className={classnames(isDesktop && classes.clearMargin)}>
      {isDesktop ? (
        <Header
          title={"Integrations"}
          onBackClick={handleBack}
          classNames={{
            container: "component-wrapper-x",
          }}
          right={<LearnMoreCta href={learn_more_href} />}
        />
      ) : (
        <LearnMoreStrip
          href={learn_more_href}
          about={`about integrating ${title}`}
          className="px-3"
        />
      )}
      <CodesHeader
        isEmpty={isEmpty}
        isDesktop={isDesktop}
        onAddCode={showCodeModal}
      />

      <ExlyTable
        ra_props={props}
        fieldsLeftPadded
        borderedFields
        drawerFieldsBordered
        noExportButton
        layoutProps={layoutProps}
        customEmptyState={
          <EmptyState isDesktop={isDesktop} onAddCode={showCodeModal} />
        }
        hasMobileFooter={!isEmpty}
        footerProps={footerProps}
        recordFooterVariant="secondary"
        columnConfig={columnConfig}
        getRecordFooterClassName={(record) =>
          custom_code_activation_status.ACTIVE === record.status &&
          classes.selectedFooter
        }
        primaryActions={isDesktop && moreActions}
        customPrimaryAction={{
          label: "More Actions",
          onClick: handleMoreActionsClick,
        }}
        desktopCustomPrimaryAction={{
          renderCustomAction: (row, rows, setAnchorEl, setOpenPopper) => (
            <DesktopActions
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setOpenPopper(true);
                setSelectedCode(row);
              }}
            />
          ),
        }}
      />

      {isMoreActionsActive && (
        <MobileActions
          open={isMoreActionsActive}
          onClose={hideMoreActions}
          actions={moreActions}
        />
      )}

      {isCodeModalActive && (
        <AddCodeModal
          open={isCodeModalActive}
          onClose={onCloseModal}
          initialData={selectedCode}
        />
      )}
    </div>
  );
};

export default withComponentLibraryTheme(CustomCodes);
