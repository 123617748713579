import ExlyTable from "common/Components/ExlyTable";
import React from "react";
import { FeatureCreditItem } from "../../components/FeatureCreditItem";
import CurrentSubscriptionBanner from "../../components/CurrentSubscriptionBanner";
import { Button } from "@my-scoot/component-library-legacy";
import moduleStyle from "./mySubscriptions.module.css";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CartIcon from "@material-ui/icons/ShoppingCart";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import { Header } from "common/Components/Header";
import { getRAUrlParams, navigateTo } from "utils/urlUtils";
import { app_route_keys } from "constants/urlPaths";
import { useCart } from "../../hooks/useCart";
import { is_empty } from "utils/validations";
import { useDispatch } from "react-redux";
import { EXLY_ACCOUNT__GET_CART } from "../../redux/actions";
import { usePayment } from "../../hooks/usePayment";
import { PaymentStatusModal } from "utils/Payments/PaymentStatusModal";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import { MobileActions } from "./MobileActions";

export const MySubscriptions = withComponentLibraryTheme((props) => {
  const isDesktop = useDesktopMediaQuery();
  useAppLayoutControl({ control: { bottomNav: !isDesktop } });
  const { getOrderStatus } = usePayment();
  const dispatch = useDispatch();
  const { getCartItemsCount, handleGoToCart } = useCart();
  const cartCount = getCartItemsCount();

  const [payment_status, set_payment_status] = React.useState(null);

  React.useEffect(() => {
    (async () => {
      dispatch({ type: EXLY_ACCOUNT__GET_CART, forceUpdate: true });

      const orderId = getRAUrlParams()?.get("order");
      if (!is_empty(orderId)) {
        const orderData = await getOrderStatus(orderId);
        set_payment_status(orderData?.status);
      }
    })();
  }, []);

  return (
    <>
      <Header
        onBackClick={() => (window.location.href = "")}
        title={"My Subscriptions"}
        classNames={{
          container: `px-4 ${moduleStyle.header}`,
        }}
        right={
          <div
            onClick={handleGoToCart}
            className={`d-flex align-items-center ${moduleStyle.cartContainer} pointer`}
          >
            <div className={`position-relative`}>
              <CartIcon />
              {!is_empty(cartCount) ? (
                <div className={`${moduleStyle.cartCount}`}>{cartCount}</div>
              ) : null}
            </div>
            {isDesktop && <span>Cart</span>}
          </div>
        }
      />
      <div className="p-3">
        <div>
          <CurrentSubscriptionBanner />
        </div>

        <div className={`${moduleStyle.separator} my-4 w-100`} />

        <div
          className={"d-flex align-itmes-center justify-content-between w-100"}
        >
          <div className="d-flex align-items-center">
            <span className={`${moduleStyle.title}`}>Account usage</span>
            {/* commenting this ; will be used when monthly credits anabled */}
            {/* <Tooltip
              title={
                "There are two different limits for items. One is a lifetime limit, and the other is a monthly limit"
              }
              color="secondary"
              arrow
              tooltipClassName={`${moduleStyle.tooltip}`}
              arrowClassName={`${moduleStyle.tooltipArrow}`}
            >
              <InfoIcon className={`${moduleStyle.info_icon} ml-1`} />
            </Tooltip> */}
          </div>
          <div
            className={`d-flex align-items-center ${moduleStyle.transactions}`}
          >
            <ListAltIcon />
            <Button
              onClick={() =>
                navigateTo(app_route_keys.subscription_transactions)
              }
              color="link"
              variant="text"
              disableTouchRipple={true}
            >
              See transactions
            </Button>
          </div>
        </div>

        <ExlyTable
          ra_props={{ ...props }}
          noExportButton
          layoutProps={{
            showFixedBars: true,
            noShadow: true,
            paddingBottom: "32px",
            paddingMobile: "0px",
          }}
          primaryKey="id"
          gridView
          GridComponent={({ record }) =>
            FeatureCreditItem({
              record,
            })
          }
        />
      </div>

      {!is_empty(payment_status) && (
        <PaymentStatusModal
          payment_status={payment_status}
          set_payment_status={set_payment_status}
        />
      )}
      {!is_empty(cartCount) && <MobileActions />}
    </>
  );
});
