import React from "react";

import classnames from "classnames";

import ExlyModal from "common/Components/ExlyModal";

import WhiteDot from "assets/vectors/recorded-content/white_dot.svg";
import NewRank from "./HelperComponents/NewRank";
import RankArrow from "./HelperComponents/RankArrow";
import DownIcon from "assets/vectors/recorded-content/down_icon.svg";

import styles from "./evaluateRankModal.module.css";
import { evaluateRank } from "../evaluateModal.ApiCalls";
import { pluralise } from "utils/Utils";

const EvaluateRankModal = ({ open, rankData, loading, onClose, onConfirm }) => {
  const {
    [evaluateRank.responseKeys.newRank]: newRank,
    [evaluateRank.responseKeys.oldRank]: oldRank,
  } = rankData;

  const isHighRank = newRank < oldRank;
  const level = Math.abs(newRank - oldRank);
  const levelInfo = `${level} ${pluralise("level", level)}`;
  const info = isHighRank ? (
    `Based on this student's final exam score, their rank is set to improve significantly by ${levelInfo}, propelling them forward on the leaderboard! 🚀`
  ) : (
    <>
      Based on this student&apos;s final exam score, their rank is expected to
      decline by {levelInfo}, dropping their position on the leaderboard.{" "}
      <img src={DownIcon} alt="down" className={styles.downIcon} />
    </>
  );

  return (
    <div>
      <ExlyModal
        open={open}
        onClose={onClose}
        title="Rank updated"
        modal_props={{
          modalPaperClassName: styles.modalPaperClassName,
          footerBtnsPosition: "space-between",
        }}
        secondaryBtnProps={{
          fullWidth: true,
          color: "secondary",
          variant: "outlined",
          className: styles.secondaryBtn,
        }}
        primaryBtnProps={{
          fullWidth: true,
          loading,
        }}
        primaryBtnText="Publish score"
        secondaryBtnText="Go back"
        onPrimaryBtnClick={onConfirm}
        className={styles.root}
        showSecondaryBtn
        mobile_modal_props={{
          footerClassName: styles.footerClassName,
        }}
      >
        <p className={styles.info}>{info}</p>

        <div className={styles.rankContainer}>
          <RankArrow down={!isHighRank} />

          <div
            className={classnames(
              styles.newRank,
              !isHighRank && styles.newRankLow
            )}
          >
            <NewRank higher={isHighRank} />
            New Rank: {newRank}
          </div>

          <div
            className={classnames(
              styles.currentRank,
              !isHighRank && styles.currentRankTop
            )}
          >
            <img src={WhiteDot} alt="dot" />
            Current Rank: {oldRank}
          </div>
        </div>
      </ExlyModal>
    </div>
  );
};

export default EvaluateRankModal;
