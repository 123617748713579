import React from "react";
import { Card, Button } from "@my-scoot/component-library-legacy";
import moduleStyle from "./featureCreditItem.module.css";
import Historyicon from "@material-ui/icons/History";
import { is_empty } from "utils/validations";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { useCart } from "../../hooks/useCart";
import { CounterButton } from "../CounterButton";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { CreditsProgress } from "./CreditsProgress";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

export const FeatureCreditItem = withComponentLibraryTheme(({ record }) => {
  const { addItemToCart, getItemQuantityInCart } = useCart();

  const purchasePlan = React.useMemo(() => {
    const credit_purchase_plan = record?.credit_purchase_plan;
    if (is_empty(credit_purchase_plan)) return {};
    return {
      price: `${getUserCurrencySymbol()}${credit_purchase_plan.price}`,
      quantity: `${credit_purchase_plan.units} ${credit_purchase_plan.unit_name}`,
    };
  }, []);

  const cartItemPayload = {
    feature_key: record.feature_key,
    units: record?.credit_purchase_plan?.units,
    price: record?.credit_purchase_plan?.price,
    unit_name: record?.credit_purchase_plan?.unit_name,
    feature_name: record?.feature_name,
  };

  const handleLedger = () => {
    window.location.href = `${window.location.origin}/#${app_pathnames[
      app_route_keys.ledger
    ](record?.feature_key)}`;
  };

  return (
    <Card className={`${moduleStyle.container} py-3 px-2 d-flex flex-column`}>
      <div className={`d-flex justify-content-between align-items-center`}>
        <div className={`${moduleStyle.name}`}>{record?.feature_name}</div>
        <div
          onClick={handleLedger}
          className={`${moduleStyle.history} pointer`}
        >
          <Historyicon className={`${moduleStyle.history_icon}`} /> History
        </div>
      </div>

      <CreditsProgress record={record} />

      {!is_empty(purchasePlan) && (
        <div className={`d-flex justify-content-between align-items-center`}>
          <div className="d-flex align-items-center">
            <span className={`${moduleStyle.priceText}`}>
              {purchasePlan?.price}
            </span>
            &nbsp;/&nbsp;
            <span className={`${moduleStyle.quantityText} line_max_1`}>
              {purchasePlan?.quantity}
            </span>
          </div>

          {!is_empty(getItemQuantityInCart(record?.feature_key)) ? (
            <CounterButton record={cartItemPayload} />
          ) : (
            <Button
              onClick={() => addItemToCart(cartItemPayload)}
              size="thin"
              color="primary"
            >
              Buy more limit
            </Button>
          )}
        </div>
      )}
    </Card>
  );
});
