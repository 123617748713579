import { dataProvider } from "data";
import { recordedContentAPI } from "schedule-v2/RecordedContent/recordedContent.api";

export const fetchResponsesList = async ({
  listingUuid,
  bookingUuid,
  type = "forms",
  page,
}) => {
  const { data, status } = await dataProvider.custom_request(
    recordedContentAPI.report(type),
    "GET",
    {
      listing_uid: listingUuid,
      booking_uid: bookingUuid,
      page,
    }
  );
  if (status !== 200)
    throw new Error(`Error creating questionnaire: ${data} ${status}`);

  const { exams, forms, total_pages } = data;
  return { data: exams || forms, totalPages: total_pages };
};

export const fetchResponses = async ({
  username,
  questionnaireUid,
  contentUid,
}) => {
  const { data, status } = await dataProvider.custom_request(
    recordedContentAPI.responses,
    "GET",
    {
      username,
      questionairre_uid: questionnaireUid,
      content_uid: contentUid,
    }
  );
  if (status !== 200)
    throw new Error(`Error creating questionnaire: ${data} ${status}`);

  return data;
};

export const fetchResponsesKeys = {
  questionnaireType: "questionnaire_type",
  title: "title",
  questions: "questions",
  username: "username",
};
