import ExlyTable from "common/Components/ExlyTable";
import { Header } from "common/Components/Header";
import { app_route_keys } from "constants/urlPaths";
import React from "react";
import { navigateTo } from "utils/urlUtils";
import {
  getFilterConfig,
  getLedgerTableConfig,
  tableChipsFormatter,
} from "./ledger.tableConfig";
import { useLedger } from "../../hooks/useLedger";
import { is_empty } from "utils/validations";
import { CreditsProgress } from "../../components/FeatureCreditItem/CreditsProgress";
import moduleStyle from "./ledger.module.css";
import { useCheckCreditExpiry } from "common/Components/UBP/CheckCreditExpiry/useCheckCreditExpiry";

export const Ledger = (props) => {
  const feature_key = props?.match?.params?.feature_key;
  const { featureTriggers, fetchLedgerTriggers } = useLedger(feature_key);
  const { featureCreditsData } = useCheckCreditExpiry({ feature_key });

  React.useEffect(() => {
    fetchLedgerTriggers();
  }, []);

  return (
    <div>
      <Header
        onBackClick={() => navigateTo(app_route_keys.my_subscriptions)}
        title={`Usage history${
          is_empty(featureCreditsData?.name)
            ? ""
            : ` / ${featureCreditsData?.name}`
        }`}
        classNames={{
          container: `px-3`,
        }}
      />
      {!is_empty(featureCreditsData) && (
        <>
          <div
            className={`${moduleStyle.progress} d-flex flex-column px-3 pb-3`}
          >
            <CreditsProgress record={featureCreditsData} />
          </div>
          <div className={`${moduleStyle.separator} mx-3`} />
        </>
      )}
      <div className={`p-3`}>
        <ExlyTable
          ra_props={props}
          tableFilters={
            featureTriggers ? getFilterConfig({ featureTriggers }) : false
          }
          tableChipsFormatter={
            featureTriggers ? tableChipsFormatter({ featureTriggers }) : false
          }
          columnConfig={getLedgerTableConfig({ featureTriggers })}
          primaryKey="id"
          noExportButton={true}
          borderedFields={true}
          fieldsAlignment="space-between"
          layoutProps={{
            showFixedBars: true,
            noShadow: true,
            paddingBottom: "32px",
            paddingMobile: "0px",
            noMobileBoxShadow: true,
          }}
        />
      </div>
    </div>
  );
};
