import React from "react";

const RankArrow = ({ down }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="78"
      viewBox="0 0 21 78"
      fill="none"
      style={
        down
          ? {
              transform: "rotate(180deg)",
            }
          : undefined
      }
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4596 17.25L10.5003 0L0.541016 17.25H8V78H13V17.25H20.4596Z"
        fill="url(#paint0_linear_4740_2836)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4740_2836"
          x1="10.3632"
          y1="-5"
          x2="10"
          y2="139"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={down ? "#FFCDCA" : "#9ED9C5"} />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RankArrow;
