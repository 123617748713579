import React from "react";

import { CircularProgress } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import StarBadgeIcon from "assets/vectors/recorded-content/star_badge_green.svg";

import { Dropdown, Tooltip } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import moment from "moment";
import { is_empty } from "utils/validations";
import {
  COURSE_ANALYTICS_STATS_DATA,
  COURSE_ANALYTICS_STATS_KEYS,
  aggregateFilterOptions,
  columnKeys,
} from "../../data";
import { useStyles } from "./helperComponents.styles";
import { roundOffIfDecimal } from "features/Common/modules/DataTypes/utils/numbers";

const DesktopStat = ({
  loading,
  icon,
  label,
  value,
  tooltip,
  color,
  hasCertification,
  hasAssessments,
  showValue,
}) => {
  const classes = useStyles({ color });
  const displayValue = showValue
    ? showValue({ hasAssessments, hasCertification })
      ? value
      : "N/A"
    : value;

  return (
    <div className={classes.desktopStatRoot}>
      <img src={icon} />
      <div>
        <div className={classes.statTitle}>
          <div>{label}</div>
          <Tooltip
            color="primary"
            arrow
            title={tooltip({ hasAssessments, hasCertification })}
          >
            <InfoIcon />
          </Tooltip>
        </div>
        {loading ? (
          <CircularProgress className={classes.statLoader} />
        ) : (
          <div className={classes.statValue}>{displayValue}</div>
        )}
      </div>
    </div>
  );
};

const MobileStat = ({
  loading,
  icon,
  label,
  value,
  tooltip,
  color,
  hasCertification,
  hasAssessments,
  showValue,
}) => {
  const classes = useStyles({ color });
  const displayValue = showValue
    ? showValue({ hasAssessments, hasCertification })
      ? value
      : "N/A"
    : value;

  return (
    <div className={classes.mobileStatRoot}>
      <div className={classes.mobileStatHeader}>
        <img src={icon} />
        <Tooltip
          color="primary"
          arrow
          title={tooltip({ hasAssessments, hasCertification })}
        >
          <InfoIcon />
        </Tooltip>
      </div>

      {loading ? (
        <CircularProgress className={classes.statLoader} />
      ) : (
        <div className={classnames(classes.statValue, classes.mobileStatValue)}>
          {displayValue}
        </div>
      )}

      <div className={classes.statLabel}>{label}</div>
    </div>
  );
};

const Stat = ({ isDesktop, bgColor, ...statProps }) => {
  const classes = useStyles({ bgColor });
  const StatComponent = isDesktop ? DesktopStat : MobileStat;

  return (
    <div className={classes.statRoot}>
      <StatComponent {...statProps} />
    </div>
  );
};

export const Stats = ({
  stats,
  loading,
  isDesktop,
  hasCertification,
  hasAssessments,
}) => {
  const classes = useStyles({ isDesktop });

  return is_empty(stats) ? null : (
    <div className={classes.statsRoot}>
      {Object.values(COURSE_ANALYTICS_STATS_KEYS).map((statKey) => (
        <Stat
          key={statKey}
          value={stats[statKey]}
          loading={loading}
          isDesktop={isDesktop}
          hasCertification={hasCertification}
          hasAssessments={hasAssessments}
          {...COURSE_ANALYTICS_STATS_DATA[statKey]}
        />
      ))}
    </div>
  );
};

export const CourseProgressField = ({ record, source, className }) => {
  const classes = useStyles();
  const isDesktop = useDesktopMediaQuery();

  const progress = roundOffIfDecimal(record[source], 1) || 0;

  return isDesktop ? (
    <div className={classnames(classes.courseProgressRoot, className)}>
      <CircularProgress
        className={classes.circularProgress}
        variant="determinate"
        value={progress}
      />
      <div className={classes.courseProgress}>{progress}%</div>
    </div>
  ) : (
    <div className={classes.mobileCourseProgressRoot}>
      <span>Completed: </span>
      <span className={classes.progress}>{progress}%</span>
    </div>
  );
};

export const CertificateStatusField = ({ record, isDesktop = true }) => {
  const classes = useStyles();

  const isIssued = record?.[columnKeys.isCertificateIssued];

  if (!isIssued && !isDesktop) return <div />;

  return isIssued ? (
    <div className={classes.certificateStatus}>
      <img src={StarBadgeIcon} alt="" />
      Certificate issued
    </div>
  ) : (
    "N/A"
  );
};

export const ActionField = ({ record, label, showCta = true, onClick }) => {
  const classes = useStyles();

  return showCta ? (
    <div
      role="button"
      className={classes.actionField}
      onClick={() => onClick?.(record)}
    >
      {label}
    </div>
  ) : null;
};

export const DaysFilter = ({ isDesktop, value, onChange }) => {
  const classes = useStyles({ isDesktop });

  const interval = value - 1;
  const start_date = moment(
    new Date(new Date().setDate(new Date().getDate() - interval))
  ).format("MMM D");
  const end_date = moment(new Date()).format("MMM D");

  return (
    <div className={classes.daysFilter}>
      <Dropdown
        value={value}
        placeholder="placeholder"
        options={aggregateFilterOptions}
        handleChange={onChange}
        minWidth="150px"
      />
      <p>
        {start_date} - {end_date}
      </p>
    </div>
  );
};
