import arrayMutators from "final-form-arrays";
import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import ExlyModal from "common/Components/ExlyModal";
import ExlyTableEmptyState from "common/Components/ExlyTable/ExlyTableEmptyState/ExlyTableEmptyState";
import EvaluateRankModal from "./EvaluateRankModal/EvaluateRankModal";
import { ModalFooter, QuestionCard } from "./HelperComponents/HelperComponents";

import { CircularProgress } from "@material-ui/core";
import EmptyStateIcon from "assets/vectors/recorded-content/course_responses_empty_state.svg";

import { is_empty } from "utils/validations";

import { useNotifications, useToggleState } from "utils/hooks";
import { fetchResponsesKeys } from "../../contentResponses.ApiCalls";
import useResponseData from "../ResponsesModal/useResponseData";

import {
  QUESTION_KEYS,
  QUESTION_OPTION_KEYS,
  QUESTION_TYPES,
} from "schedule-v2/components/recorded-content/recordedContent.data";
import { EXAM_RESPONSE_STATUS } from "../../ContentResponses.enum";
import { formKeys } from "./evaluateModal.data";

import { responseColumnKeys } from "../../data";
import { evaluateExam, evaluateRank } from "./evaluateModal.ApiCalls";
import { formatErrorMessage, getTotalMarks } from "./evaluateModal.utils";

import { onboardingFeatureKeys } from "common/Components/FeatureOnboarding/FeatureOnboarding.constants";
import useFeatureOnboardingChecker from "common/Components/FeatureOnboarding/useFeatureOnboardingChecker";

import styles from "./evaluateModal.module.css";

const emptyStateProps = {
  empty_list_icon: EmptyStateIcon,
  alt: "no responses yet",
  content_title: "No responses yet.",
};

const EvaluateModal = ({ open, onClose, refetch, record, listingUuid }) => {
  const { notify } = useNotifications();
  const { data, loading: loadingResponses } = useResponseData({
    record,
    onError: onClose,
  });
  const { isOnboarded: isNoteShown, handleFeatureOnboardChange } =
    useFeatureOnboardingChecker(
      onboardingFeatureKeys.EVALUATE_EXAM_MARKS_NOTE_SHOWN
    );
  const {
    isOnboarded: hideEvaluateMarksNote,
    handleFeatureOnboardChange: handleHideEvaluateMarksNote,
  } = useFeatureOnboardingChecker({
    featureKey: onboardingFeatureKeys.HIDE_EVALUATE_MARKS_NOTE,
  });

  const [publishing, setPublishing] = useState(false);
  const [rankData, setRankData] = useState({});
  const [isRankModalOpen, openRankModal, closeRankModal] =
    useToggleState(false);

  const loading = loadingResponses;

  const { [fetchResponsesKeys.questions]: questions } = data;
  const { [responseColumnKeys.lastAttempt]: lastAttempt } = record || {};
  const {
    [responseColumnKeys.attemptUid]: attemptUid,
    [responseColumnKeys.status]: status,
  } = lastAttempt || {};
  const isPendingEvaluation =
    status === EXAM_RESPONSE_STATUS.PENDING_EVALUATION;

  const initialMarks = useMemo(
    () =>
      [...Array(questions?.length)].map((item, index) => {
        const question = questions?.[index];
        const {
          [QUESTION_KEYS.questionUid]: questionUid,
          [QUESTION_KEYS.totalMarks]: totalMarks,
          [QUESTION_KEYS.markReceived]: marksReceived,
          [QUESTION_KEYS.questionType]: questionType,
          [QUESTION_KEYS.answer]: answer,
          [QUESTION_KEYS.options]: options,
        } = question || {};

        const isOptionTypeQuestion =
          questionType === QUESTION_TYPES.SINGLE_SELECT ||
          questionType === QUESTION_TYPES.MULTI_SELECT;
        const isAnyOptionSelected = options?.some(
          (option) => option[QUESTION_OPTION_KEYS.isSelected]
        );
        const hasAnswer = isOptionTypeQuestion
          ? isAnyOptionSelected
          : !is_empty(answer);
        const prefilledMarksWhileEvaluation = hasAnswer ? totalMarks : 0;

        return {
          [formKeys.quesUid]: questionUid,
          [formKeys.marksReceived]:
            (isPendingEvaluation
              ? prefilledMarksWhileEvaluation
              : marksReceived) || 0,
          [formKeys.totalMarks]: totalMarks,
        };
      }),
    [questions]
  );

  const handlePublish = async (formValues) => {
    try {
      setPublishing(true);
      const { [formKeys.questions]: marksCollected } = formValues;
      const payload = marksCollected.map((mark) => {
        delete mark[formKeys.totalMarks];
        return mark;
      });
      await evaluateExam({ attemptUid, payload });
      notify("Result published", "success");
      refetch();
      onClose();
      closeRankModal();
    } catch (error) {
      notify("Something went wrong while saving exam scores.", "error");
    } finally {
      setPublishing(false);
    }
  };

  const handleEvaluateRank = async (formValues) => {
    try {
      const { [formKeys.questions]: marksCollected } = formValues;
      const totalMarks = marksCollected.reduce((total, mark) => {
        const { [formKeys.marksReceived]: marksReceived } = mark;
        return total + marksReceived;
      }, 0);
      const {
        [responseColumnKeys.lastAttempt]: {
          [responseColumnKeys.attemptUid]: attemptUid,
        },
      } = record;
      const data = await evaluateRank({
        examMarks: totalMarks,
        attemptUid,
        listingUid: listingUuid,
      });
      const { [evaluateRank.responseKeys.isRankUpdated]: isRankUpdated } = data;
      if (isRankUpdated) {
        setRankData(data);
        openRankModal();
      } else {
        handlePublish(formValues);
      }
    } catch (error) {
      notify("Something went wrong while evaluating rank.", "error");
    }
  };

  return (
    <Form
      onSubmit={handleEvaluateRank}
      initialValues={{
        [formKeys.questions]: initialMarks,
      }}
      mutators={{
        ...arrayMutators,
      }}
    >
      {({ handleSubmit, submitting, invalid, errors, values }) => {
        const handleFormSubmit = (e) => {
          if (invalid)
            formatErrorMessage(errors).forEach(
              (error) => error && notify(error, "error")
            );
          handleSubmit(e);
        };

        return (
          <form onSubmit={handleFormSubmit}>
            <ExlyModal
              open={open}
              onClose={onClose}
              title="Evaluate exam"
              id="mainLayout"
              customFooter={
                <ModalFooter
                  onClick={handleFormSubmit}
                  score={`${getTotalMarks(
                    values[formKeys.questions],
                    formKeys.marksReceived
                  )} / ${getTotalMarks(questions, QUESTION_KEYS.totalMarks)}`}
                  showCta={isPendingEvaluation}
                  primaryBtnProps={{
                    loading: submitting,
                  }}
                />
              }
              modal_props={{
                modalPaperClassName: styles.modalPaperClassName,
              }}
              className={styles.modalRoot}
            >
              {loading ? (
                <div className={styles.loader}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {is_empty(questions) && (
                    <ExlyTableEmptyState
                      classes={{
                        content_wrapper: styles.emptyState,
                      }}
                      {...emptyStateProps}
                    />
                  )}
                  <div className={styles.questions}>
                    <FieldArray name={formKeys.questions}>
                      {({ fields }) =>
                        fields?.map((name, index) => (
                          <QuestionCard
                            key={name}
                            name={name}
                            index={index}
                            question={questions?.[index]}
                            disabled={!isPendingEvaluation}
                            isNoteShown={isNoteShown}
                            hideEvaluateMarksNote={hideEvaluateMarksNote}
                            onShowNote={() => handleFeatureOnboardChange(true)}
                            onHideNote={() => handleHideEvaluateMarksNote(true)}
                          />
                        ))
                      }
                    </FieldArray>
                  </div>
                </>
              )}
            </ExlyModal>

            <EvaluateRankModal
              open={isRankModalOpen}
              onClose={closeRankModal}
              onConfirm={() => handlePublish(values)}
              rankData={rankData}
              loading={publishing}
            />
          </form>
        );
      }}
    </Form>
  );
};

export default EvaluateModal;
