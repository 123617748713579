import constants from "constants/constants";
import { useCart } from "./useCart";
import { getUserCurrencySymbol } from "utils/AuthUtil";
import { dataProvider } from "data";
import { FETCH_ORDER_STATUS_API, INITIATE_PAYMENT_API } from "../account.apis";
import { apiMethods } from "data/api.constants";
import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { isInteger, is_empty } from "utils/validations";
import { initiateJuspay } from "features/Payment/utils/Payment.utils";
import { isRequestSuccessful } from "utils/Utils";
import { notification_color_keys, useNotifications } from "utils/hooks";

export const usePayment = () => {
  const { notify } = useNotifications();
  const { cartList } = useCart();

  const getPaymentDetails = () => {
    let totalAmount = 0;
    for (let item of cartList) {
      totalAmount += item.quantity * item.price;
    }
    const gstAmount = (constants.GST_PERCENTAGE / 100) * totalAmount;
    const grossAmount = totalAmount + gstAmount;

    return {
      totalAmount: `${getUserCurrencySymbol()}${totalAmount}`,
      gstAmount: `${getUserCurrencySymbol()}${gstAmount}`,
      grossAmount: `${getUserCurrencySymbol()}${grossAmount}`,
      gstPercent: `${constants.GST_PERCENTAGE}%`,
    };
  };

  const initiatePayment = async () => {
    try {
      const response = await dataProvider.custom_request(
        INITIATE_PAYMENT_API,
        apiMethods.POST,
        {
          return_url: `${window.location.origin}/#/${
            app_route_ids[app_route_keys.my_subscriptions]
          }`,
        }
      );
      if (
        isRequestSuccessful(response?.status) &&
        !is_empty(response?.data?.order)
      ) {
        initiateJuspay(response.data.order);
      } else {
        notify(constants.error_message, notification_color_keys.error);
      }
    } catch (err) {
      const errMsg = err?.body?.message ?? constants.error_message;
      notify(errMsg, notification_color_keys.error);
    } finally {
    }
  };

  const getOrderStatus = (orderId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
      try {
        const response = await dataProvider.custom_request(
          FETCH_ORDER_STATUS_API,
          apiMethods.GET,
          { order_uid: orderId }
        );

        if (
          isRequestSuccessful(response?.status) &&
          isInteger(response?.data?.order?.status)
        ) {
          return resolve(response?.data?.order);
        }
        return resolve(null);
      } catch (err) {
        const errMsg = err?.body?.messafe ?? constants.error_message;
        notify(errMsg, notification_color_keys.error);
        return resolve(null);
      }
    });
  };

  return {
    getPaymentDetails,
    initiatePayment,
    getOrderStatus,
  };
};
