import React from "react";
import { SCORING_TYPES } from "schedule-v2/components/recorded-content/ExamCreation/modals/ScoringType/scoringType.data";
import { EXAM_RESPONSE_STATUS } from "./ContentResponses.enum";
import {
  ExamsMobileHeader,
  ResponsesActionField,
  StatusField,
} from "./components/HelperComponents/HelperComponents";

export const ResponseLabels = {
  EXAM: "Exam",
  FORM: "Form",
};

export const RESPONSES = {
  FORMS: "forms",
  EXAMS: "exams",
};

export const STATUS = {
  PASSED: "Passed",
  FAILED: "Failed",
};

export const RESPONSE_TABS_OPTIONS = [
  {
    label: "Forms",
    value: RESPONSES.FORMS,
  },
  {
    label: "Exams",
    value: RESPONSES.EXAMS,
  },
];

export const responseColumnKeys = {
  title: "title",
  questionnaireUid: "questionairre_uid",
  contentUid: "content_uid",
  attemptUid: "attempt_uuid",
  lastAttempt: "last_attempt",
  submission: "submission",
  totalAttempts: "total_attempts",
  username: "username",
  customerId: "customer_id",
  marksObtained: "marks_obtained",
  scoringType: "scoring_type",
  isAbandoned: "is_abandoned",

  // lastAttempt sub keys
  questionsCount: "questions_count",
  answersCount: "answers_count",
  status: "status", // passed
  timeTakenSinceBooking: "time_taken_since_booking",
};

export const columnConfig = (props = {}) => {
  const { tab, handleShowResponses, handleEvaluate } = props;

  const columns = [
    {
      field: responseColumnKeys.title,
      headerName:
        tab === RESPONSES.FORMS ? ResponseLabels.FORM : ResponseLabels.EXAM,
      fixed: "left",
      isPrimary: true,
      renderColumn: (record) => (
        <ExamsMobileHeader record={record} source={responseColumnKeys.title} />
      ),
    },
    {
      field: responseColumnKeys.lastAttempt,
      headerName: "Answered",
      valueFormatter: (record) => {
        const dataKey =
          tab === RESPONSES.EXAMS
            ? responseColumnKeys.lastAttempt
            : responseColumnKeys.submission;
        return `${record?.[dataKey]?.[responseColumnKeys.answersCount] || 0}/${
          record?.[dataKey]?.[responseColumnKeys.questionsCount] || 0
        } questions answered`;
      },
    },
    {
      field: responseColumnKeys.totalAttempts,
      headerName: "Attempts",
      hiddenDesktopColumn: tab === RESPONSES.FORMS,
    },
    {
      field: responseColumnKeys.lastAttempt,
      headerName: "Status",
      valueFormatter: (record) => <StatusField record={record} />,
      hiddenDesktopColumn: tab === RESPONSES.FORMS,
    },
    {
      field: responseColumnKeys.scoringType,
      headerName: "Scoring type",
      valueFormatter: (record) =>
        record[responseColumnKeys.scoringType] === SCORING_TYPES.AUTOMATIC
          ? "Automated"
          : "Manual",
      hiddenDesktopColumn: tab === RESPONSES.FORMS,
      hidden: true,
    },
    {
      field: "",
      headerName: "Actions",
      valueFormatter: (record) => (
        <ResponsesActionField
          record={record}
          onShowResponses={handleShowResponses(record)}
          onEvaluate={() => handleEvaluate(record)}
          isExams={tab === RESPONSES.EXAMS}
        />
      ),
      fixed: "right",
      hidden: true,
    },
  ];

  return columns;
};

export const contentResponsesFilterKeys = {
  title: "title",
  examScoringType: "exam_scoring_type",
  lastAttemptStatus: "last_attempt_status",
};

const examStatusLabel = {
  [EXAM_RESPONSE_STATUS.PASSED]: "Passed",
  [EXAM_RESPONSE_STATUS.FAILED]: "Failed",
  [EXAM_RESPONSE_STATUS.PENDING_EVALUATION]: "Unmarked",
};

const scoringTypeLabels = {
  [SCORING_TYPES.AUTOMATIC]: "Automatic",
  [SCORING_TYPES.MANUAL]: "Manual",
};

export const filterConfig = ({ isAutoScoringFilterSelected }) => [
  {
    source: contentResponsesFilterKeys.title,
    placeholder: "Exam name",
    type: "input",
    disableContains: true,
  },
  {
    source: contentResponsesFilterKeys.examScoringType,
    placeholder: "Scoring type",
    type: "dropdown",
    disableContains: true,
    options: [
      {
        id: 1,
        label: scoringTypeLabels[SCORING_TYPES.AUTOMATIC],
        value: SCORING_TYPES.AUTOMATIC,
      },
      {
        id: 2,
        label: scoringTypeLabels[SCORING_TYPES.MANUAL],
        value: SCORING_TYPES.MANUAL,
      },
    ],
  },
  {
    source: contentResponsesFilterKeys.lastAttemptStatus,
    placeholder: "Status",
    type: "dropdown",
    disableContains: true,
    options: [
      {
        id: 1,
        label: examStatusLabel[EXAM_RESPONSE_STATUS.PASSED],
        value: EXAM_RESPONSE_STATUS.PASSED,
      },
      {
        id: 2,
        label: examStatusLabel[EXAM_RESPONSE_STATUS.FAILED],
        value: EXAM_RESPONSE_STATUS.FAILED,
      },
      {
        id: 2,
        label: examStatusLabel[EXAM_RESPONSE_STATUS.PENDING_EVALUATION],
        value: EXAM_RESPONSE_STATUS.PENDING_EVALUATION,
        hidden: isAutoScoringFilterSelected,
      },
    ],
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case contentResponsesFilterKeys.title:
      return `Exam name: ${value}`;
    case contentResponsesFilterKeys.lastAttemptStatus:
      return `Scoring type: ${examStatusLabel[value]}`;
    case contentResponsesFilterKeys.examScoringType:
      return `Scoring type: ${scoringTypeLabels[value]}`;
    default:
      return undefined;
  }
};
