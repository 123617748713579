import React, { useMemo } from "react";
import { Form } from "react-final-form";

import ExlyModal from "common/Components/ExlyModal";
import useStyles from "./styles";
import { getEntityChangeStateMapping, getInitialValues } from "./utils";
import { customCodeLabels, offering_specific_pages } from "../../Codes.config";
import AddCodeFormFields from "./AddCodeFormFields";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { useRefresh } from "react-admin";
import { is_empty } from "utils/validations";
import { createCustomCode, updateCustomCode } from "../../Codes.api.helpers";
import { logError } from "utils/error";
import {
  DISABLE_CUSTOM_CODE_UPDATION_IN_MODERATION_MODE,
  custom_code_activation_status,
} from "features/CustomCode/CustomCode.constants.js";

const AddCodeModal = ({ open, onClose, initialData }) => {
  const classes = useStyles();
  const { notify } = useNotifications();
  const refresh = useRefresh();

  const [processing, setProcessing] = React.useState(false);

  const initialValues = useMemo(
    () => getInitialValues(initialData),
    [initialData]
  );

  const onSubmit = (formState) => {
    if (processing) return;
    const {
      name,
      code_snippet,
      target,
      code_position,
      applicable_pages,
      form_mode,
      metadata,
      uuid,
      status,
    } = formState;

    if (status === custom_code_activation_status.IN_MODERATION) {
      return notify(
        DISABLE_CUSTOM_CODE_UPDATION_IN_MODERATION_MODE,
        notification_color_keys.error
      );
    }

    let apiCall, payload;
    let listings_mapping = [];
    let sales_page_mapping = [];
    let thankyou_page_mapping = [];

    if (
      applicable_pages.includes(offering_specific_pages.CHECKOUT_PAGE) ||
      applicable_pages.includes(offering_specific_pages.SOLD_OUT_PAGE) ||
      !is_empty(initialValues.listings_mapping)
    )
      listings_mapping = getEntityChangeStateMapping(
        initialValues.metadata.listings,
        metadata.listings
      );
    if (
      applicable_pages.includes(offering_specific_pages.SALES_PAGES) ||
      !is_empty(initialValues.sales_page_mapping)
    )
      sales_page_mapping = getEntityChangeStateMapping(
        initialValues.metadata.sales_pages,
        metadata.sales_pages
      );
    if (
      applicable_pages.includes(
        offering_specific_pages.THANKYOU_PAGE_PURCHASE
      ) ||
      applicable_pages.includes(offering_specific_pages.THANKYOU_PAGE_OPTIN) ||
      !is_empty(initialValues.thankyou_page_mapping)
    )
      thankyou_page_mapping = getEntityChangeStateMapping(
        initialValues.metadata.thankyou_pages,
        metadata.thankyou_pages
      );

    let payloadValues = {
      name,
      code_snippet,
      target,
      code_position,
      ...(!is_empty(applicable_pages) && { applicable_pages }),
      ...(!is_empty(listings_mapping) && { listings_mapping }),
      ...(!is_empty(sales_page_mapping) && { sales_page_mapping }),
      ...(!is_empty(thankyou_page_mapping) && { thankyou_page_mapping }),
      metadata,
    };

    if (form_mode === "view") {
      apiCall = createCustomCode;
      payload = payloadValues;
    } else {
      payload = {
        code_uuid: uuid,
        updated_values: payloadValues,
      };
      apiCall = updateCustomCode;
    }
    setProcessing(true);
    apiCall(payload)
      .then(() => {
        notify("Code details have been successfully saved!", "success");
        refresh();
        onClose();
      })
      .catch((error) => {
        notify(
          error?.body?.message ?? "Failed to save code!",
          notification_color_keys.error
        );
        logError({
          error,
          when: "onSubmit code",
          occuredAt:
            "src/ui/pages/CustomCodes/components/AddCodeModal/AddCodeModal.jsx",
        });
      })
      .finally(() => {
        setProcessing(false);
      });
  };

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {({
        handleSubmit,
        form,
        submitting,
        values,
        pristine,
        ...restFormProps
      }) => {
        const handleCancel = () => {
          onClose();
          form.reset();
        };

        return (
          <form onSubmit={handleSubmit}>
            <ExlyModal
              open={open}
              onClose={handleCancel}
              title={customCodeLabels.addButton}
              onPrimaryBtnClick={handleSubmit}
              primaryBtnText={processing ? "..." : customCodeLabels.save}
              primaryBtnProps={{
                type: "submit",
                disabled:
                  (values.form_state === "edit" && pristine) || submitting,
              }}
              padded={false}
              modal_props={{
                modalPaperClassName: classes.desktopModalPaper,
              }}
              mobile_modal_props={{
                secondaryAction: {
                  label: "Cancel",
                  onClick: handleCancel,
                },
              }}
            >
              <AddCodeFormFields values={values} {...restFormProps} />
            </ExlyModal>
          </form>
        );
      }}
    </Form>
  );
};

export default React.memo(AddCodeModal);
