import { MobileFooter } from "common/Components/MobileFooter";
import React from "react";
import { useCart } from "../../hooks/useCart";
import { pluralise } from "utils/Utils";
import { usePayment } from "../../hooks/usePayment";
import { Button } from "@my-scoot/component-library-legacy";
import CartIcon from "@material-ui/icons/ShoppingCart";
import moduleStyle from "./mySubscriptions.module.css";

export const MobileActions = () => {
  const { getCartItemsCount, handleGoToCart } = useCart();
  const { getPaymentDetails } = usePayment();

  const count = getCartItemsCount();
  const totalAmount = getPaymentDetails();

  return (
    <MobileFooter>
      <div className={`d-flex justify-content-between align-items-center`}>
        <div>
          <div className={`${moduleStyle.itemCount}`}>
            {count} {pluralise("item", count)} in cart
          </div>
          <div className={`${moduleStyle.totalPrice}`}>
            {totalAmount?.totalAmount}
          </div>
        </div>

        <Button onClick={handleGoToCart} className={`${moduleStyle.cart}`}>
          <CartIcon className={`${moduleStyle.cartIcon}`} />
          &nbsp;&nbsp;View Cart
        </Button>
      </div>
    </MobileFooter>
  );
};
