export const form_data = {
  created_at: {
    key: "created_at",
    label: "Date",
  },
  trigger: {
    key: "trigger",
    label: "Category",
    sub_heading: "sub_heading",
  },
  operation: {
    key: "operation",
    label: "Credits Usage",
  },
  date: {
    key: "order_date",
    label: "Date",
    start_date: { key: "start_date", label: "Start date" },
    end_date: { key: "end_date", label: "End date" },
  },
};

export const LEDGER_OPERATIONS = {
  1: {
    value: 1,
    color: "rgba(0, 183, 122, 1)",
    symbol: "+",
    type: "FEATURE_CREDIT",
    label: "Credit",
  },
  2: {
    value: 2,
    color: "rgba(191, 38, 0, 1)",
    symbol: "-",
    label: "Debit",
    type: "FEATURE_DEBIT",
  },
};
