import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    clearMargin: {
      width: "100%",
      paddingBottom: "48px",
    },
    desktopHeader: {
      background:
        "linear-gradient(273.23deg, rgba(220, 217, 242, 0.5) 1.72%, rgba(217, 217, 217, 0) 105.11%)",
      height: "176px",
      padding: "0 52px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "24px",
    },
    desktopHeadLeftSection: {
      display: "flex",
      gap: "32px",
    },
    desktopIcon: {
      width: "80px",
      height: "80px",
    },
    selectedFooter: {
      background: theme.palette.fine_pine.shade_50,
    },
    mobileIcon: {
      width: "40px",
      height: "40px",
    },
    desktopTitle: {
      fontSize: "30px",
      lineHeight: "36px",
      letterSpacing: "0.04em",
      color: "#000",
    },

    font_16: {
      fontSize: "16px",
      lineHeight: "19px",
    },
    font_14: {
      fontSize: "14px",
      lineHeight: "17px",
    },
    mobileHeader: {
      padding: "20px 16px 20px 16px",
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
