import { CircularProgress } from "@material-ui/core";
import {
  checkIsArrayEmpty,
  EXAM_SCORING_TYPES,
} from "@my-scoot/exly-react-component-lib";
import ExlyModal from "common/Components/ExlyModal";
import { ExlyDropdown } from "common/form";
import { required } from "common/validate";
import useExamsList from "features/RecordedContent/utils/useExamsList";
import React from "react";
import { Field, Form } from "react-final-form";
import styles from "./PublishExamResults.module.css";
import { Label } from "schedule-v2/components/recorded-content/ExamCreation/components/HelperComponents/HelperComponents";
import { ExlyNote } from "common/Components/ExlyNote/ExlyNote";
import { PUBLISH_EXAM_RESULT_FORM_KEYS } from "./constants/PublishExamResults.constants";
import {
  BULK_ACTION_TABLES,
  BULK_ACTION_TYPES,
} from "features/BulkActions/BulkActions.constants";
import { publishAutomatedExamResult } from "./PublishExamResults.apiCalls";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { logError } from "utils/error";

const PublishExamResults = ({
  open,
  onClose,
  selectedRecords,
  selectAll,
  listingUuid,
  onSuccess,
}) => {
  const { notify } = useNotifications();
  const { loading, exams } = useExamsList({
    listingUuid,
    scoringType: EXAM_SCORING_TYPES.AUTO,
    isReportPublished: false,
  });

  const examOptions = checkIsArrayEmpty(exams)
    ? [{ label: "no exams created yet", value: null, disabled: true }]
    : exams?.map(({ title, questionairre_uid }) => ({
        label: title,
        value: questionairre_uid,
      }));

  const handlePublishResults = async (formData) => {
    const {
      [PUBLISH_EXAM_RESULT_FORM_KEYS.questionairre_uid]: questionnaireUid,
    } = formData;

    const payload = {
      bulk_action_table: BULK_ACTION_TABLES.AUTOMATED_EXAM_RESULTS,
      action_type: BULK_ACTION_TYPES.PUBLISH_AUTOMATED_EXAM_RESULTS,
      filter_data: {
        questionairre_uid: questionnaireUid,
      },
    };

    if (selectAll) {
      payload.select_all = selectAll;
    } else {
      payload.selected_ids = Object.values(selectedRecords).map(
        ({ customer_username }) => customer_username
      );
    }

    const handleSuccess = () => {
      notify("Success", notification_color_keys.success);
      onSuccess();
      onClose();
    };

    const handleError = (error) => {
      notify(
        error?.message ||
          "Something went wrong while publishing results, please try again",
        notification_color_keys.error
      );
      logError({
        error,
        occuredAt:
          "src/ui/pages/CourseAnalytics/modules/PublishExamResults/PublishExamResults.jsx",
        when: "calling publishAutomatedExamResult",
        severity: "blocker",
      });
    };

    return publishAutomatedExamResult(payload)
      .then(handleSuccess)
      .catch(handleError);
  };

  return (
    <Form onSubmit={handlePublishResults}>
      {({ submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ExlyModal
            title="Select automated exam"
            open={open}
            onClose={onClose}
            primaryBtnText="Publish results"
            onPrimaryBtnClick={handleSubmit}
            primaryBtnProps={{
              loading: submitting,
              disabled: submitting,
            }}
            modal_props={{
              modalPaperClassName: styles.modalRoot,
            }}
            className={styles.root}
          >
            {loading ? (
              <div className={styles.loading}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <Field
                  name={PUBLISH_EXAM_RESULT_FORM_KEYS.questionairre_uid}
                  component={ExlyDropdown}
                  label={
                    <Label
                      label="Select exam for which you want to publish results"
                      required
                    />
                  }
                  placeholder="Select exam"
                  size="large"
                  validate={required}
                  options={examOptions}
                  fullWidth
                />

                <ExlyNote
                  className={styles.note}
                  label="Only students who have submitted the selected exam will receive results. Those who have already received them won’t get them again."
                />
              </>
            )}
          </ExlyModal>
        </form>
      )}
    </Form>
  );
};

export default PublishExamResults;
