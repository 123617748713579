import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { matchPath } from "react-router";
import { getRAUrlPathName } from "utils/urlUtils";

const useResponses = ({ openResponsesModal, setResponsesData }) => {
  const pathname = getRAUrlPathName();
  const matchResults = matchPath(pathname, {
    path: app_route_ids[app_route_keys.recorded_content_responses],
  });
  const type = matchResults?.params?.type;

  const handleShowResponses = (record) => () => {
    setResponsesData(record);
    openResponsesModal();
  };

  return {
    type,
    handleShowResponses,
  };
};

export default useResponses;
