import React from "react";
import { is_empty } from "utils/validations";
import {
  default_credits_color,
  default_credits_expired_percentage,
  default_credits_warning_percentage,
  expired_credits_color,
  null_allotted_color,
  progress_background_color,
  warning_credits_color,
} from "../../account.constants";
import { ExlyProgress } from "common/Components/ExlyProgress";
import moduleStyle from "./featureCreditItem.module.css";

export const CreditsProgress = ({ record }) => {
  const freeCredits = React.useMemo(() => {
    let freeData = record?.free_credits;

    if (is_empty(freeData)) return null;
    let progressColor = default_credits_color;
    if (is_empty(freeData?.allotted)) progressColor = null_allotted_color;
    else {
      if (
        freeData?.consumed >=
        ((record.warning_percent ?? default_credits_warning_percentage) / 100) *
          freeData?.allotted
      ) {
        progressColor = warning_credits_color;
      }

      if (
        freeData?.consumed >=
        ((record.error_percent ?? default_credits_expired_percentage) / 100) *
          freeData?.allotted
      ) {
        progressColor = expired_credits_color;
      }
    }

    return {
      ...freeData,
      label: "Free limit",
      limit_text: `${freeData?.consumed} of ${freeData?.allotted} used`,
      progressColor,
      progressHeight: !is_empty(record?.paid_credits) ? 12 : 20,
    };
  }, []);

  const paidCredits = React.useMemo(() => {
    let paidData = record?.paid_credits;

    if (is_empty(paidData) || is_empty(paidData?.allotted)) return null;
    let progressColor = default_credits_color;
    if (is_empty(paidData?.allotted)) progressColor = null_allotted_color;
    else {
      if (
        paidData?.consumed >=
        ((record.warning_percent ?? default_credits_warning_percentage) / 100) *
          paidData?.allotted
      ) {
        progressColor = warning_credits_color;
      }
      if (
        paidData?.consumed >=
        ((record.error_percent ?? default_credits_expired_percentage) / 100) *
          paidData?.allotted
      ) {
        progressColor = expired_credits_color;
      }
    }

    return {
      ...paidData,
      label: "Purchased limit",
      limit_text: `${paidData?.consumed} of ${paidData?.allotted} used`,
      progressColor,
      progressHeight: 12,
    };
  }, []);

  return (
    <div
      className={`d-flex flex-column justify-content-around flex-grow-1 position-relative`}
    >
      {!is_empty(freeCredits) && (
        <div>
          <div className={`d-flex justify-content-between align-items-center`}>
            <div className={`${moduleStyle.limit_label}`}>
              {freeCredits.label}
            </div>
            <div className={`${moduleStyle.limit_text}`}>
              {freeCredits.limit_text}
            </div>
          </div>

          <ExlyProgress
            progressBackgroundColor={progress_background_color}
            progressHeight={freeCredits.progressHeight}
            progressColor={freeCredits.progressColor}
            stepsCount={freeCredits.allotted}
            activeStep={freeCredits.consumed}
          />
        </div>
      )}

      {!is_empty(paidCredits) && (
        <div>
          <div className={`d-flex justify-content-between align-items-center`}>
            <div className={`${moduleStyle.limit_label}`}>
              {paidCredits.label}
            </div>
            <div className={`${moduleStyle.limit_text}`}>
              {paidCredits.limit_text}
            </div>
          </div>

          <ExlyProgress
            progressBackgroundColor={progress_background_color}
            progressHeight={paidCredits.progressHeight}
            progressColor={paidCredits.progressColor}
            stepsCount={paidCredits.allotted}
            activeStep={paidCredits.consumed}
          />
        </div>
      )}
    </div>
  );
};
