import { is_empty } from "utils/validations";
import {
  custom_code_target,
  entity_change_state,
  offering_specific_pages,
} from "../../Codes.config";
import { THANKYOU_PAGE_TYPES } from "ui/pages/ThankYou/constants/Thankyou.constants";

export const getInitialValues = (initialData) => {
  return initialData
    ? {
        ...initialData,
        metadata: {
          listings: initialData.metadata?.listings || [],
          sales_pages: initialData.metadata?.sales_pages || [],
          thankyou_pages: initialData.metadata?.thankyou_pages || [],
        },
        form_mode: "edit",
      }
    : {
        name: "",
        code_snippet: "",
        target: "",
        code_position: [],
        form_mode: "view",
        applicable_pages: [],
        listings_mapping: [],
        sales_page_mapping: [],
        metadata: {
          listings: [],
          sales_pages: [],
          thankyou_pages: [],
        },
      };
};

/**
 * This function create the entity state change mapped list while comparing the
 * original List and changed List
 * entity state change can have following values
 * NO_CHANGE, ADDED, UPDATED, DELETED
 * Since its based on ids(unique key) so its always created/deleted/nochange can't be updated
 * @param {*} initialList original list containing entity ids
 * @param {*} changedList changed list containing entity ids
 * @returns
 */
export const getEntityChangeStateMapping = (
  initialList = [],
  changedList = []
) => {
  // Find added and no change entities(ids)
  const presentIds = changedList.map((changedId) => {
    const id = initialList.find((initialId) => initialId === changedId);
    if (id)
      return {
        uuid: changedId,
        change_state: entity_change_state.NO_CHANGE,
      };
    else
      return {
        uuid: changedId,
        change_state: entity_change_state.NEW_ADDED,
      };
  });

  // Find deleted entities
  const removedItems = initialList.filter((initialId) => {
    const id = changedList.find((changedId) => changedId === initialId);
    return !id;
  });
  const removedIds = removedItems.map((removedId) => ({
    uuid: removedId,
    change_state: entity_change_state.DELETED,
  }));

  // return mapping of both with desired change_state mapping
  return presentIds.concat(removedIds);
};

export const handleShowOfferingSelector = (target) =>
  target === custom_code_target.SPECIFIC_OFFERING_PAGES;

export const handleShowSpecificPagesSelector = (listings) =>
  !is_empty(listings);

export const handleShowSalesPgsSelector = (pages = []) =>
  pages.includes(offering_specific_pages.SALES_PAGES);

export const getFilteredSalesPgsByListings = (
  listings = [],
  salesPgOptions = []
) => {
  return listings.reduce((acc, value) => {
    salesPgOptions.forEach((salesPg) => {
      if (salesPg.listing_uuid === value) {
        acc = [...acc, salesPg.uuid];
      }
    });
    return acc;
  }, []);
};

export const getFilteredThankyouPgs = (
  applicable_pages,
  thankyouPgList = []
) => {
  let pageTypes = [];
  applicable_pages.forEach((page) => {
    if (page === offering_specific_pages.THANKYOU_PAGE_OPTIN)
      pageTypes.push(THANKYOU_PAGE_TYPES.OPTIN.value);
    else if (page === offering_specific_pages.THANKYOU_PAGE_PURCHASE)
      pageTypes.push(THANKYOU_PAGE_TYPES.PURCHASE.value);
  });
  return thankyouPgList.reduce((acc, thankyouPg) => {
    pageTypes.forEach((pageType) => {
      if (thankyouPg.page_type === pageType) {
        acc = [...acc, thankyouPg.uuid];
      }
    });
    return acc;
  }, []);
};
