import React from "react";

import { CircularProgress } from "@material-ui/core";
import ExlyModal from "common/Components/ExlyModal";
import ExlyTableEmptyState from "common/Components/ExlyTable/ExlyTableEmptyState/ExlyTableEmptyState";
import { FormQuestion } from "schedule-v2/components/recorded-content/ExamCreation/pages/Questionnaire/HelperComponents/components/HelperComponents";

import {
  QUESTIONNAIRE_TYPES,
  QUESTION_KEYS,
} from "schedule-v2/components/recorded-content/recordedContent.data";
import { fetchResponsesKeys } from "../../contentResponses.ApiCalls";

import { is_empty } from "utils/validations";

import classnames from "classnames";
import styles from "./responsesModal.module.css";

import EmptyStateIcon from "assets/vectors/recorded-content/course_responses_empty_state.svg";
import useResponseData from "./useResponseData";

const emptyStateProps = {
  empty_list_icon: EmptyStateIcon,
  alt: "no responses yet",
  content_title: "No responses yet.",
};

const ResponsesModal = ({ open, onClose, responsesData }) => {
  const { data, loading } = useResponseData({
    record: responsesData,
    onError: onClose,
  });
  const {
    [fetchResponsesKeys.questions]: questions,
    [fetchResponsesKeys.title]: title,
    [fetchResponsesKeys.questionnaireType]: questionnaireType,
  } = data;

  const isExam = questionnaireType === QUESTIONNAIRE_TYPES.EXAM;

  return (
    <ExlyModal
      open={open}
      onClose={onClose}
      title={title}
      customFooter={<></>}
      modal_props={{
        modalPaperClassName: styles.modalPaperClassName,
      }}
      className={styles.modalRoot}
    >
      {loading ? (
        <div className={styles.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          {is_empty(questions) ? (
            <ExlyTableEmptyState
              classes={{
                content_wrapper: styles.emptyState,
              }}
              {...emptyStateProps}
            />
          ) : (
            isExam && (
              <div className={styles.infoRoot}>
                <div className={classnames(styles.info, styles.correct)}>
                  Correct
                </div>
                <div className={classnames(styles.info, styles.inCorrect)}>
                  Incorrect
                </div>
              </div>
            )
          )}

          {questions?.map((question, index) => {
            const {
              [QUESTION_KEYS.question]: questionLabel,
              [QUESTION_KEYS.isMandatory]: isMandatory,
            } = question;
            return (
              <div key={question[QUESTION_KEYS.questionUid]}>
                <div className={styles.question}>
                  Q{index + 1}. {questionLabel}
                  {isMandatory && <span>*</span>}
                </div>
                <FormQuestion question={question} isResponse isExam={isExam} />
              </div>
            );
          })}
        </>
      )}
    </ExlyModal>
  );
};

export default ResponsesModal;
