import React from "react";
import moduleStyle from "./subscriptionTransactions.module.css";
import LoupeIcon from "@material-ui/icons/Loupe";
import { ReactComponent as AutoFixHigh } from "assets/vectors/autoFixHigh.svg";

export const form_data = {
  name: {
    key: "title",
    label: "Item",
  },
  type: {
    key: "type",
    label: "Category",
  },
  date: {
    key: "order_date",
    label: "Date",
    start_date: { key: "start_date", label: "Start date" },
    end_date: { key: "end_date", label: "End date" },
  },
  status: {
    key: "status",
    label: "Status",
  },
  amount: {
    key: "amount",
    label: "Amount",
  },
  invoice_url: {
    key: "invoice_url",
    label: "Action",
  },
};

export const transaction_statuses = {
  success: {
    text: <div className={`${moduleStyle.successStatus}`}>Success</div>,
    value: 2,
    label: "Success",
  },
  pending: {
    text: <div className={`${moduleStyle.pendingStatus}`}>Pending</div>,
    value: 7,
    label: "Pending",
  },
  failed: {
    text: <div className={`${moduleStyle.failureStatus}`}>Failed</div>,
    value: 8,
    label: "Failed",
  },
};

export const category_statuses = {
  plan_purchase: {
    label: "Plan purchase",
    value: 2,
    key: "Plan",
    icon: (
      <div className={`${moduleStyle.autoFixIcon} mr-2`}>
        <AutoFixHigh />
      </div>
    ),
  },
  add_on: {
    label: "Add-on purchase",
    value: 1,
    key: "Feature Credit",
    icon: (
      <div className={`${moduleStyle.loupeIcon} mr-2`}>
        <LoupeIcon />
      </div>
    ),
  },
};

export const emptyStateProps = {
  content_title: "Nothing here yet",
  empty_list_icon: `assets/images/Empty/search.png`,
  alt: "no transaction",
  children: `You can find records here after you’ve made a transaction`,
};
