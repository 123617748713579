import React from "react";
import { Checkbox } from "@my-scoot/component-library-legacy";
import styles from "./OfferingSpecificPageOptions.module.css";

const OfferingSpecificPageOption = ({ isSelected, option, onChange }) => (
  <div onClick={() => onChange(option.value)} className={styles.Wrapper}>
    <Checkbox checked={isSelected} tabIndex={-1} size="large" />
    <div className={styles.OptionLabel}>{option.label}</div>
  </div>
);

export default OfferingSpecificPageOption;
