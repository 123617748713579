import { Header } from "common/Components/Header";
import React from "react";
import moduleStyle from "./subscriptionTransactions.module.css";
import {
  InvoiceField,
  getFilterConfig,
  getSubscriptionTransactionsTableConfig,
  tabFilterConfig,
  tableChipsFormatter,
} from "./subscriptionTransactions.tableConfig";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import ExlyTable from "common/Components/ExlyTable";
import { navigateTo } from "utils/urlUtils";
import { app_route_keys } from "constants/urlPaths";
import ExlyModal from "common/Components/ExlyModal";
import { is_empty } from "utils/validations";
import {
  emptyStateProps,
  form_data,
} from "./subscriptionTransactions.constants";

export const SubscriptionTransactions = withComponentLibraryTheme((props) => {
  const [showMoreData, setShowMoreData] = React.useState(null);

  return (
    <div className={`d-flex flex-column h-100`}>
      <Header
        onBackClick={() => navigateTo(app_route_keys.my_subscriptions)}
        title={"Transaction history"}
        classNames={{
          container: `px-3`,
        }}
      />
      <div className={`${moduleStyle.container} p-3 flex-grow-1`}>
        <ExlyTable
          ra_props={props}
          tableFilters={getFilterConfig()}
          tabFilterConfig={tabFilterConfig}
          tableChipsFormatter={tableChipsFormatter}
          columnConfig={getSubscriptionTransactionsTableConfig(setShowMoreData)}
          primaryKey="id"
          noExportButton={true}
          customPrimaryAction={{
            label: InvoiceField(),
            onClick: (row) => {
              window.open(row[form_data.invoice_url.key]);
            },
          }}
          borderedFields={true}
          fieldsAlignment="space-between"
          layoutProps={{
            showFixedBars: true,
            noShadow: true,
            paddingBottom: "32px",
            paddingMobile: "0px",
            noMobileBoxShadow: true,
          }}
          {...emptyStateProps}
        />
      </div>

      <ExlyModal
        onClose={() => setShowMoreData(null)}
        title={"Items in this purchase"}
        modal_props={{ modalPaperClassName: moduleStyle.dataModal }}
        open={!is_empty(showMoreData?.features)}
        customFooter={<></>}
      >
        <ul className="p-3">
          {showMoreData?.features?.map((item) => {
            return (
              <li key={item.name}>
                {item?.credits} {item?.name}
              </li>
            );
          })}
        </ul>
      </ExlyModal>
    </div>
  );
});
