import React from "react";
import classnames from "classnames";
import { Checkbox } from "@my-scoot/component-library-legacy";
import styles from "./SalesPageOption.module.css";

const salesPgStatusMap = {
  0: {
    label: "Draft",
    background: "coral_red",
  },
  1: {
    label: "Live",
    background: "fine_pine",
  },
  2: {
    label: "In Waitlist",
    background: "danger_red",
  },
  3: {
    label: "Past",
    background: "butterscotch_yellow",
  },
  5: {
    label: "Deleted",
    background: "danger_red",
  },
};

const SalesPageDescription = ({
  cover_image,
  title,
  description,
  isCondensed,
  showCheckbox,
  isSelected,
  hide_description = false,
  cover_icon = false,
}) => {
  return (
    <div className={styles.LeftSection}>
      {showCheckbox && (
        <Checkbox checked={isSelected} tabIndex={-1} size="large" />
      )}
      {cover_icon ? (
        <div
          className={classnames(
            styles.coverIcon,
            isCondensed && styles.smallIcon
          )}
        >
          {cover_icon}
        </div>
      ) : (
        <img
          src={cover_image}
          className={classnames(
            styles.coverImage,
            isCondensed && styles.smallImg
          )}
        />
      )}
      <div>
        <div
          className={classnames(
            styles.font_14,
            !isCondensed && !hide_description && "mb-2"
          )}
        >
          {title}
        </div>
        {!hide_description && (
          <div className={styles.font_12}>{description}</div>
        )}
      </div>
    </div>
  );
};

const SalesPageOption = ({
  option,
  isSelected,
  isLast,
  lastElementRef,
  onChange,
  showStatus,
  showCheckbox,
}) => {
  const { label, background } = salesPgStatusMap[option.status] || {};
  return (
    <div
      onClick={() => onChange(option.value)}
      className={styles.salePgOptionWrapper}
      {...(isLast ? { ref: lastElementRef } : {})}
    >
      <SalesPageDescription
        {...option}
        isSelected={isSelected}
        showCheckbox={showCheckbox}
      />

      {showStatus && label && (
        <div className={classnames(styles.status, styles[background])}>
          {label}
        </div>
      )}
    </div>
  );
};

export default SalesPageOption;
