import React from "react";
import moduleStyle from "./counterButton.module.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { useCart } from "../../hooks/useCart";
import ExlyLoader from "ui/modules/ExlyLoader";

/**
 * button for increase/decrease count of cart item
 * @param {*} record feautre credit item
 * @returns button JSX
 */
export const CounterButton = ({ record }) => {
  const {
    addItemToCart,
    getItemQuantityInCart,
    removeItemFromCart,
    isProcessing,
  } = useCart();

  return (
    <div
      className={`${moduleStyle.counter} d-flex align-items-center px-1 py-2`}
    >
      {isProcessing(record?.feature_key) ? (
        <ExlyLoader showText={false} />
      ) : (
        <>
          <RemoveIcon
            onClick={() => removeItemFromCart(record)}
            className={`${moduleStyle.counterIcon} mr-4 pointer`}
          />
          {getItemQuantityInCart(record?.feature_key)}
          <AddIcon
            onClick={() => addItemToCart(record)}
            className={`${moduleStyle.counterIcon} ml-4 pointer`}
          />
        </>
      )}
    </div>
  );
};
