import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    desktopModalPaper: {
      width: "677px",
      maxWidth: "677px",
    },
    contentWrap: {
      padding: "20.5px 24px 0px 24px",
      position: "relative",
    },
    alertWrap: {
      position: "sticky",
      bottom: 0,
      left: 0,
      right: 0,
      margin: "32px -24px 0 -24px",
      width: "calc(100% + 48px)",
      height: "62px",
      background: theme.palette.butterscotch_yellow.shade_50,
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      fontSize: "14px",
    },
    mt: {
      marginTop: "18px",
    },
    mb: {
      marginBottom: "18px",
    },
    mb_12: {
      marginBottom: "12px",
    },
    gap_24: {
      gap: "24px",
    },
    gap_12: {
      gap: "12px",
    },
    p_0: {
      padding: 0,
    },
    borderBottom: {
      borderBottom: `1.5px solid ${theme.palette.secondary.shade_100}`,
    },
    formTitle: {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    heading: {
      fontSize: "16px",
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    errorMsg: {
      color: "#BF2600",
      fontSize: "11px",
    },
    pageSelectWrap: {
      borderBottom: `1.5px solid ${theme.palette.secondary.shade_100}`,
      paddingBottom: "16px",
    },
    offeringDropWrap: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "40px",
      boxSizing: "border-box",
      border: ({ error }) =>
        `1.5px solid ${
          error
            ? theme?.palette?.basic?.danger_red
            : theme.palette.secondary.shade_200
        }`,
      borderRadius: "5px",
      paddingLeft: "12px",
      fontSize: "16px",
    },
    errorText: {
      color: theme?.palette?.basic?.danger_red,
      fontSize: "11px",
    },
    selectedTitles: {
      flexGrow: 1,
      color: ({ showPlaceholder }) =>
        showPlaceholder
          ? theme.palette.basic.light_gray
          : theme.palette.secondary.main,
    },
    colorGray: {
      color: theme.palette.basic.light_gray,
    },
    popover_paper: {
      boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
      borderRadius: "12px",
      width: ({ offeringWrapWidth }) => offeringWrapWidth,
      maxHeight: "400px",
    },
    yelloish: {
      color: "#FFAA00",
    },
    loaderWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "64px",
    },
    loadingImage: {
      width: "32px",
      height: "32px",
    },
    offering: {
      borderTop: `1.5px solid ${theme.palette.secondary.shade_100}`,
      padding: "16.5px 24px 16px 24px",
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    selectAll: {
      padding: "16.5px 24px 16px 24px",
      display: "flex",
      gap: "10px",
      alignItems: "center",
    },
    cover_image: {
      width: "57px",
      height: "36px",
      background: "#D9D9D9",
      borderRadius: "5px",
      marginLeft: "4px",
    },
    font_14: {
      fontSize: "14px",
      lineHeight: "17px",
    },
    font_12: {
      fontSize: "12px",
      lineHeight: "14px",
    },
    emptyOfferingWrap: {
      padding: "45.5px 24px 24px 24px",
    },
    emptyContent: {
      fontSize: "12px",
      marginTop: "14px",
      marginBottom: "24px",
    },
    errorTextWrap: {
      paddingTop: "10px",
    },
    "@media (max-width:780px)": {
      offering: {
        padding: "16.5px 16px 16px 16px",
      },
      selectAll: {
        padding: "16.5px 16px 16px 16px",
      },
      emptyOfferingWrap: {
        padding: "45.5px 16px 24px 16px",
      },
      contentWrap: {
        padding: "20.5px 16px 98px 16px",
        height: "calc(100vh - 59.5px - 85px - 80px)",
        overflowY: "scroll",
        boxSizing: "border-box",
      },
      alertWrap: {
        margin: 0,
        width: "100%",
        position: "fixed",
        bottom: "80px",
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
