import React from "react";
import { Card } from "@my-scoot/component-library-legacy";
import moduleStyle from "./currentSubscriptionBanner.module.css";
import { useDispatch, useSelector } from "react-redux";
import { EXLY_ACCOUNT__GET_CREATOR_ACTIVE_PLAN } from "../../redux/actions";
import { is_empty } from "utils/validations";
import moment from "moment";
import { DATE_FIRST_DATE_FORMAT } from "constants/dateTime";
import FileIcon from "@material-ui/icons/Description";
import RenewIcon from "@material-ui/icons/Autorenew";
import { ReactComponent as AutoFixHigh } from "assets/vectors/autoFixHigh.svg";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { warning_plan_days_to_expire } from "../../account.constants";
import { openPricingPage } from "utils/Utils";
import { SUBSCRIPTION_PLAN } from "constants/account.constants";
import { HighlightedFeatureCard } from "features/Account/modules/HighlightedFeatureCard/HighlightedFeatureCard";

const CurrentSubscriptionBanner = () => {
  const dispatch = useDispatch();
  const notify = useNotifications();
  const creatorPlan = useSelector((state) => state.myAccount.creatorPlan);
  const highlightedFeature = useSelector((state) => state.myAccount.highlights);

  const daysToExpire = React.useMemo(() => {
    if (creatorPlan.start_datetime && creatorPlan.end_datetime) {
      return moment(creatorPlan.start_datetime).diff(
        moment(creatorPlan.end_datetime),
        "days"
      );
    }
  }, [creatorPlan]);

  const isPremium = creatorPlan?.name === SUBSCRIPTION_PLAN.PREMIUM;

  React.useEffect(() => {
    dispatch({ type: EXLY_ACCOUNT__GET_CREATOR_ACTIVE_PLAN });
  }, []);

  if (is_empty(creatorPlan)) return null;

  const handleDownload = () => {
    if (is_empty(creatorPlan?.invoice_url)) {
      return notify(SOMETHING_WENT_WRONG, notification_color_keys.error);
    }
    window.open(creatorPlan?.invoice_url);
  };

  return (
    <div className={`row no-gutters justify-content-between align-items-start`}>
      <div className={`col-12 col-md-6 px-md-3`}>
        <Card className={`${moduleStyle.container} w-100 `}>
          <div id="CurrentSubscriptionBanner">
            {!is_empty(daysToExpire) &&
            daysToExpire > 0 &&
            daysToExpire <= warning_plan_days_to_expire ? (
              <div
                onClick={openPricingPage}
                className={`d-flex justify-content-center ${moduleStyle.expireWarning} align-items-center`}
              >
                <span>Plan renews in {daysToExpire} days.</span>&nbsp;
                <RenewIcon />
                <span className={`underline`}>Renew now</span>
              </div>
            ) : null}
            <div className={` p-3`}>
              <div
                className={`${moduleStyle.your_plan_text} d-flex justify-content-between align-items-center`}
              >
                <div>YOUR PLAN</div>
                {!is_empty(creatorPlan?.invoice_url) && (
                  <div
                    onClick={handleDownload}
                    className={`${moduleStyle.download} pointer`}
                  >
                    <FileIcon />{" "}
                    <span className={`underline pointer`}>
                      Download invoice
                    </span>
                  </div>
                )}
              </div>
              <div className={`${moduleStyle.plan_name} my-2`}>
                {creatorPlan?.name}
              </div>
              <div className={`d-flex`}>
                {!is_empty(creatorPlan.start_datetime) && (
                  <div className={`mb-2 fit_content mr-3`}>
                    <div className={`${moduleStyle.validtyLabel}`}>
                      Plan Started on
                    </div>
                    <div className={`${moduleStyle.validtyValue}`}>
                      {moment(creatorPlan.start_datetime).format(
                        DATE_FIRST_DATE_FORMAT
                      )}{" "}
                    </div>
                  </div>
                )}
                {!is_empty(creatorPlan.end_datetime) && (
                  <div className={`${moduleStyle.validity} pl-3`}>
                    <div className={`${moduleStyle.validtyLabel}`}>
                      Valid till
                    </div>
                    <div className={`${moduleStyle.validtyValue}`}>
                      {moment(creatorPlan.end_datetime).format(
                        DATE_FIRST_DATE_FORMAT
                      )}{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {is_empty(highlightedFeature) && isPremium ? null : (
            <div
              onClick={() => (!isPremium ? openPricingPage() : null)}
              className={`${
                !isPremium ? `${moduleStyle.CTA} pointer` : moduleStyle.emptyCTA
              } d-flex justify-content-center py-2 align-items-center `}
            >
              {!isPremium && (
                <>
                  <AutoFixHigh className={`${moduleStyle.auto_high} mr-1`} />
                  Upgrade plan
                </>
              )}
            </div>
          )}
        </Card>
      </div>
      <div className="col-12 col-md-6 px-md-3">
        <HighlightedFeatureCard />
      </div>
    </div>
  );
};

export default CurrentSubscriptionBanner;
