import { useDispatch, useSelector } from "react-redux";
import { EXLY_ACCOUNT__GET_LEDGER_TRIGGERS } from "../redux/actions";

export const useLedger = (feature_key) => {
  const dispatch = useDispatch();
  const ledgerTriggers = useSelector((state) => state.myAccount.ledgerTriggers);

  const fetchLedgerTriggers = () => {
    dispatch({
      type: EXLY_ACCOUNT__GET_LEDGER_TRIGGERS,
      feature_key,
    });
  };

  return {
    fetchLedgerTriggers,
    featureTriggers: ledgerTriggers[feature_key],
  };
};
