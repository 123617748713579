import { put, takeEvery, call, select, takeLatest } from "redux-saga/effects";
import dataProvider from "../../../../data/dataProvider";
import api from "../../../../data/APIs";
import {
  EXLY_DASHBOARD__SET_LOADER,
  EXLY_DASHBOARD__REQUEST_BANNER,
  EXLY_DASHBOARD__SET_BANNER,
  EXLY_DASHBOARD__REQUEST_BANNER_TOAST,
  EXLY_DASHBOARD__SET_BANNER_TOAST,
  EXLY_DASHBOARD__REQUEST_LEADS,
  EXLY_DASHBOARD__SET_LEADS,
  EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS,
  EXLY_DASHBOARD__SET_SUBSCRIPTION_STATS,
} from "./actions";
import moment from "moment";
import constants from "constants/constants";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { whats_next_keys } from "features/Onboarding/modules/WhatsNext/WhatsNext.constants";
import { WHATS_NEXT_APIS } from "features/Onboarding/modules/WhatsNext/WhatsNext.api";
import { is_empty } from "features/Common/utils/common.utils";

/**
 * Worker Saga: to get banner data
 */
function* getBanner({ forceUpdate = false }) {
  try {
    const bannerData = yield select((state) => state.dashboard.banner);
    // @dev DO NOT FETCH VALUE EVEN IF bannerData is set to false, as initially the value was null, false denites that at least once it has been fetched.
    if (bannerData !== false && (is_empty(bannerData) || forceUpdate)) {
      yield put({ type: EXLY_DASHBOARD__SET_BANNER, payload: null });
      const response = yield call(
        dataProvider.custom_request,
        api.get_dashboard_banner,
        apiMethods.GET
      );
      if (isRequestSuccessful(response.status)) {
        const toastResponse = yield call(
          dataProvider.custom_request,
          WHATS_NEXT_APIS.GET_PROGRESS,
          apiMethods.GET,
          {
            request_params: JSON.stringify([
              whats_next_keys.has_offer_enabled.key,
            ]),
          }
        );
        if (toastResponse.data.has_offer_enabled) {
          yield put({
            type: EXLY_DASHBOARD__SET_BANNER_TOAST,
            payload: toastResponse.data.has_offer_enabled,
          });
        }
        yield put({ type: EXLY_DASHBOARD__SET_BANNER, payload: response.data });
      } else {
        throw `API: ${api.get_dashboard_banner} FAIL`;
      }
    } else {
      yield put({ type: EXLY_DASHBOARD__SET_BANNER, payload: bannerData });
    }
  } catch (error) {
    yield put({ type: EXLY_DASHBOARD__SET_BANNER, payload: false });
  } finally {
    yield put({ type: EXLY_DASHBOARD__SET_LOADER, payload: false });
  }
}

/**
 * Worker Saga: to get Toast banner data
 */
function* getBannerToast() {
  try {
    yield put({ type: EXLY_DASHBOARD__SET_BANNER_TOAST, payload: null });
    const response = yield call(
      dataProvider.custom_request,
      api.get_dashboard_banner,
      apiMethods.GET
    );
    if (isRequestSuccessful(response.status)) {
      const toastResponse = yield call(
        dataProvider.custom_request,
        WHATS_NEXT_APIS.GET_PROGRESS,
        apiMethods.GET,
        {
          request_params: JSON.stringify([
            whats_next_keys.has_offer_enabled.key,
          ]),
        }
      );
      if (isRequestSuccessful(toastResponse.status)) {
        if (constants.joiningOffer.indexOf(response.data.title) > -1) {
          yield put({
            type: EXLY_DASHBOARD__SET_BANNER_TOAST,
            payload: toastResponse.data.has_offer_enabled,
          });
        }
      }
    } else {
      throw `API: ${WHATS_NEXT_APIS.GET_PROGRESS} FAIL`;
    }
  } catch (error) {
    yield put({ type: EXLY_DASHBOARD__SET_BANNER_TOAST, payload: false });
  } finally {
    yield put({ type: EXLY_DASHBOARD__SET_LOADER, payload: false });
  }
}

/**
 * Worker Saga: to get leads
 */
function* getLeads(payload) {
  try {
    const leads = yield select((state) => state.dashboard.leads);
    const filterDays = payload.filterDays || 30;
    if (is_empty(leads[filterDays])) {
      const response = yield call(
        dataProvider.custom_request,
        api.dashboard_leads,
        "GET",
        { days: filterDays }
      );
      if (response.status === 200) {
        yield put({
          type: EXLY_DASHBOARD__SET_LEADS,
          payload: { [filterDays]: response.data },
        });
      } else {
        throw `API: ${api.dashboard_leads} FAIL`;
      }
    } else {
      yield put({
        type: EXLY_DASHBOARD__SET_LEADS,
        payload: { [filterDays]: leads[filterDays] },
      });
    }
  } catch (error) {
    yield put({ type: EXLY_DASHBOARD__SET_LEADS, payload: false });
  }
}

function* getSubscriptionStats(payload) {
  try {
    const filter_month = yield select(
      (state) => state.dashboard.selected_filter_month
    );
    let date_range = [
      moment()
        .subtract(filter_month === constants.current_month ? 0 : 1, "months")
        .startOf("month")
        .format("YYYY-MM-DD"),
      moment()
        .subtract(filter_month === constants.current_month ? 0 : 1, "months")
        .endOf("month")
        .format("YYYY-MM-DD"),
    ];
    let body = payload.show_overall
      ? {}
      : { start_date: date_range[0], end_date: date_range[1] };
    if (!is_empty(payload.listing_uid)) body.listing_uid = payload.listing_uid;
    const response = yield call(
      dataProvider.custom_request,
      api.get_subscription_stats,
      "GET",
      body
    );
    if (response.status === 200) {
      yield put({
        type: EXLY_DASHBOARD__SET_SUBSCRIPTION_STATS,
        payload: response.data,
      });
    } else {
      throw `API: ${api.dashboard_leads} FAIL`;
    }
  } catch (error) {
    yield put({ type: EXLY_DASHBOARD__SET_SUBSCRIPTION_STATS, payload: false });
  }
}

/**
 * Watcher Saga: Dashboard
 */
function* dashboardSaga() {
  yield takeEvery(EXLY_DASHBOARD__REQUEST_BANNER, getBanner);
  yield takeEvery(EXLY_DASHBOARD__REQUEST_BANNER_TOAST, getBannerToast);
  yield takeLatest(EXLY_DASHBOARD__REQUEST_LEADS, getLeads);
  yield takeLatest(
    EXLY_DASHBOARD__GET_SUBSCRIPTION_STATS,
    getSubscriptionStats
  );
}

export default dashboardSaga;
