import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    statRoot: {
      color: ({ color }) => color,
      background: ({ bgColor }) => bgColor,
      borderRadius: "12px",
      flex: 1,
      overflow: "hidden",
    },

    desktopStatRoot: {
      display: "flex",
      alignItems: "center",
      padding: "12px 16px",
      gap: "14px",
      flex: 1,
      minHeight: "75px",
      "& img": {
        maxWidth: "40px",
      },
    },

    mobileStatRoot: {
      minHeight: "138px",
      padding: "12px",
    },

    statTitle: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "20px",
      letterSpacing: "0.04em",
      marginBottom: "8px",
      "& svg": {
        fontSize: "19.21px",
      },
      color: ({ color }) => color,
    },

    statLoader: {
      height: "16px !important",
      width: "16px !important",
      color: ({ color }) => color,
    },

    statValue: {
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "22px",
      letterSpacing: "0.04em",
      color: ({ color }) => color,
    },

    mobileStatValue: {
      marginBottom: "16px",
    },

    statLabel: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "17px",
      letterSpacing: "0.04em",
      color: ({ color }) => color,
    },

    statsRoot: {
      display: "flex",
      gap: ({ isDesktop }) => (isDesktop ? "26px" : "12px"),
    },

    mobileStatHeader: {
      display: "flex",
      justifyContent: "space-between",
      "& img": {
        maxWidth: "26px",
      },
      height: "26px",
      color: ({ color }) => color,
      marginBottom: "18.5px",
    },

    courseProgressRoot: {
      position: "relative",
      height: "48px",
      width: "48px",
    },

    mobileCourseProgressRoot: {
      fontSize: "16px",
      lineHeight: "19px",
    },

    progress: {
      color: theme?.palette?.fine_pine?.main,
    },

    circularProgress: {
      height: "48px !important",
      width: "48px !important",
      color: theme?.palette?.fine_pine?.main,
    },
    courseProgress: {
      border: "1px solid #F3F3F3",
      borderRadius: "50%",
      height: "40px !important",
      width: "40px !important",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "15px",
      letterSpacing: "0.02em",
    },

    certificateStatus: {
      display: "flex",
      alignItems: "center",
      gap: "5.33px",
      "& img": {
        height: "17.18px",
      },
      color: theme?.palette?.fine_pine?.main,
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: 500,
      whiteSpace: "nowrap",
    },

    actionField: {
      whiteSpace: "nowrap",
      fontSize: "14px",
      lineHeight: "17px",
      fontWeight: 500,
      color: theme?.palette?.primary?.main,
      "&:hover": {
        textDecoration: "underline",
      },
    },

    daysFilter: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px",
      justifyContent: ({ isDesktop }) => !isDesktop && "space-between",
      flexDirection: ({ isDesktop }) => !isDesktop && "row-reverse",
      gap: "24px",
      "& p": {
        whiteSpace: "nowrap",
        margin: "0 !important",
      },
    },
  }),
  { name: "CreatorTool" }
);
