import React from "react";

import { IconButton } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import { Button } from "@my-scoot/component-library-legacy";

import styles from "./header.module.css";

const Header = ({ label, onClickSendMessage, showCta = true }) => {
  return (
    <div className={styles.root}>
      <div className={styles.back}>
        <IconButton onClick={() => window.history.go(-1)}>
          <BackIcon />
        </IconButton>
        <h1>{label}</h1>
      </div>

      {showCta && (
        <div className={styles.cta}>
          <Button onClick={onClickSendMessage}>Send message</Button>
        </div>
      )}
    </div>
  );
};

export default Header;
