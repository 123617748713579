import moment from "moment";
import { LEDGER_OPERATIONS, form_data } from "./ledger.constants";
import { DISPLAY_DATE_YEAR_TIME_FORMAT } from "constants/dateTime";
import React from "react";
import { FILTER_TYPES } from "common/Components/ExlyTable/ExlyTableFilters/filtersConstants";

const OperationField = (record) => {
  return (
    <div
      style={{
        color: LEDGER_OPERATIONS[record[form_data.operation.key]].color,
      }}
    >
      {LEDGER_OPERATIONS[record[form_data.operation.key]].symbol}
      {record.units} {record?.unit_name}
    </div>
  );
};

const TriggerField = (record, featureTriggers) => (
  <div className={`d-flex flex-column`}>
    <div>{featureTriggers?.[record[form_data.trigger.key]]}</div>
    <div className={`small font_300`}>
      {[record[form_data.trigger.sub_heading]]}
    </div>
  </div>
);

export const getLedgerTableConfig = ({ featureTriggers }) => [
  {
    field: form_data.trigger.key,
    headerName: form_data.trigger.label,
    emptyField: "N/A",
    sortable: false,
    renderColumn: (record) => TriggerField(record, featureTriggers),
    valueFormatter: (record) => TriggerField(record, featureTriggers),
  },
  {
    field: form_data.created_at.key,
    headerName: form_data.created_at.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: (record) =>
      moment(record[form_data.created_at.key]).format(
        DISPLAY_DATE_YEAR_TIME_FORMAT
      ),
    renderColumn: (record) =>
      moment(record[form_data.created_at.key]).format(
        DISPLAY_DATE_YEAR_TIME_FORMAT
      ),
  },
  {
    field: form_data.operation.key,
    headerName: form_data.operation.label,
    emptyField: "N/A",
    sortable: false,
    valueFormatter: OperationField,
    renderColumn: OperationField,
  },
];

export const getFilterConfig = ({ featureTriggers }) => {
  return [
    {
      source: form_data.trigger.key,
      placeholder: form_data.trigger.label,
      options: Object.entries(featureTriggers).map(([key, item]) => ({
        label: item,
        value: key,
      })),
      type: FILTER_TYPES.dropdown,
      disableContains: true,
    },
    {
      source: form_data.date.start_date.key,
      placeholder: form_data.date.start_date.label,
      type: FILTER_TYPES.date,
    },
    {
      source: form_data.date.end_date.key,
      placeholder: form_data.date.end_date.label,
      type: FILTER_TYPES.date,
    },
    {
      source: form_data.operation.key,
      placeholder: form_data.operation.label,
      options: Object.values(LEDGER_OPERATIONS),
      type: FILTER_TYPES.dropdown,
      disableContains: true,
    },
  ];
};

export const tableChipsFormatter =
  ({ featureTriggers }) =>
  (filter, value) => {
    switch (filter) {
      case form_data.trigger.key: {
        const triggerEntry = Object.entries(featureTriggers).find(
          // eslint-disable-next-line no-unused-vars
          ([key, _]) => value == key
        );
        // eslint-disable-next-line no-unused-vars
        const [_, item] = triggerEntry;
        return `${form_data.trigger.label}: ${item}`;
      }
      case form_data.date.start_date.key: {
        return `${form_data.date.start_date.label}: ${value}`;
      }
      case form_data.date.end_date.key: {
        return `${form_data.date.end_date.label}: ${value}`;
      }
      case form_data.operation.key: {
        return `${form_data.operation.label}: ${
          Object.values(LEDGER_OPERATIONS).find((item) => item.value === value)
            .label
        }`;
      }
      default:
        return undefined;
    }
  };
