import { dataProvider } from "data";
import { recordedContentAPI } from "schedule-v2/RecordedContent/recordedContent.api";
import { handleDateFormat } from "utils/Utils";
import { COURSE_ANALYTICS_STATS_KEYS, columnKeys } from "./data";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";

export const fetchAggregateAnalyticsData = async ({
  listing_uuid,
  days_filters = 7,
}) => {
  const { data, status } = await dataProvider.custom_request(
    recordedContentAPI.course_analytics.stats,
    "GET",
    {
      listing_uuid,
      days_filters,
    }
  );
  if (status !== 200)
    throw new Error(
      `Error fetching aggregate analytics data: ${data} ${status}`
    );
  return data;
};

fetchAggregateAnalyticsData.keys = {
  ...COURSE_ANALYTICS_STATS_KEYS,
  HAS_CERTIFICATION: "has_certification",
  HAS_EXAMS: "has_exams",
  HAS_ASSESSMENTS: "has_assessments",
  hasManualEvaluation: "has_manual_evaluation",
};

export const courseAnalyticsExporter =
  ({ hasExams, hasCertification }) =>
  (data, returnCSV = false) => {
    return new Promise((resolve, reject) => {
      try {
        const dataForExport = data.map((data) => {
          const {
            [columnKeys.customerName]: customerName,
            [columnKeys.customerEmail]: customerEmail,
            [columnKeys.courseProgress]: courseProgress = 0,
            [columnKeys.lastContentAccessed]: courseStatus,
            [columnKeys.lastVisited]: lastVisited,
          } = data;

          let columns = {
            "Customer Name": customerName,
            Email: customerEmail,
            "Course progress": `${courseProgress}%`,
            "Course status": courseStatus || "N/A",
            "Last visited on": lastVisited
              ? handleDateFormat(lastVisited)
              : "Not visited yet",
          };

          if (hasExams) {
            const {
              [columnKeys.exams]: { attempted = 0, passed = 0, total = 0 },
            } = data;
            const progress = `${attempted}/${total} attempted, ${passed} passed`;
            columns["Exam(s) progress"] = progress;
          }

          if (hasCertification) {
            const { [columnKeys.isCertificateIssued]: isCertificateIssued } =
              data;
            columns["Certificate status"] = isCertificateIssued
              ? "Certificate issued"
              : "N/A";
          }

          return columns;
        });
        jsonExport(dataForExport, (err, csv) => {
          if (returnCSV) resolve(csv);
          downloadCSV(csv, "Course Analytics");
        });
      } catch (err) {
        reject(err);
      }
    });
  };
