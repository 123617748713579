import React from "react";
import { fetchListingBasedThankyouPgs } from "../Codes.api.helpers";

export const useFetchCustomCodeTyPgs = ({ listings }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [thankyouPgList, setThankyouPgList] = React.useState([]);

  const listingsString = listings.join(",");

  React.useEffect(() => {
    setLoading(true);
    setError(false);
    fetchListingBasedThankyouPgs({
      listings: listingsString,
    })
      .then(({ thankyou_pages }) => {
        setThankyouPgList(thankyou_pages);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [listings]);

  return {
    thankyouPgList,
    loading,
    error,
  };
};
