import React, { useState } from "react";

import { Tabs } from "@my-scoot/component-library-legacy";
import MobileDataLayout from "features/CourseAnalytics/modules/ContentResponses/modules/FormsV2/modules/MobileForms/index";
import FormDataLayout from "features/CourseAnalytics/modules/ContentResponses/modules/FormsV2/modules/DesktopForms/index";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import DesktopDataLayout from "./components/DesktopDataLayout/DesktopDataLayout";
import Header from "./components/Header/Header";
import ResponsesModal from "./components/ResponsesModal/ResponsesModal";

import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { getResponseTypeBasedEmptyProps } from "features/CourseAnalytics/CourseAnalytics.utils";
import { useRefresh } from "react-admin";
import { matchPath, useHistory } from "react-router";
import { useToggleState } from "utils/hooks";
import { getRAUrlPathName } from "utils/urlUtils";
import { RESPONSES, RESPONSE_TABS_OPTIONS } from "./data";

import styles from "./contentResponses.module.css";

const ContentResponses = (props) => {
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();
  const refetch = useRefresh();

  const pathname = getRAUrlPathName();
  const matchResults = matchPath(pathname, {
    path: app_route_ids[app_route_keys.recorded_content_responses],
  });
  const type = matchResults?.params?.type;
  const listingUuid = matchResults?.params?.listing_uuid;
  const bookingUuid = matchResults?.params?.booking_uuid;
  const customerName = decodeURI(matchResults?.params?.customer_name);

  const [responsesData, setResponsesData] = useState({});
  const [currentTab, setCurrentTab] = useState(type);
  const [isResponsesModalOpen, openResponsesModal, closeResponsesModal] =
    useToggleState(false);

  const isExamsTab = currentTab === RESPONSES.EXAMS;

  const handleTabChange = (e, tab) => {
    const url = `${app_route_keys.recorded_content_responses}/${customerName}/${tab}/${listingUuid}/${bookingUuid}`;
    history.replace(`/${url}`);
    setCurrentTab(tab);
    refetch();
  };

  const title = (
    <>
      {customerName}/ <span className={styles.title}>Responses</span>
    </>
  );

  useAppLayoutControl({
    values: {
      title,
      showBottomNav: false,
    },
    control: {
      appBar: !isDesktop,
      bottomNav: !isDesktop,
      title: !isDesktop,
    },
  });

  const FormsLayout = isDesktop ? FormDataLayout : MobileDataLayout;

  const ContentLayout = isExamsTab ? DesktopDataLayout : FormsLayout;

  return (
    <>
      {isDesktop && <Header label={title} showCta={false} />}

      <div className={styles.body}>
        <Tabs
          tabs={RESPONSE_TABS_OPTIONS}
          value={currentTab}
          onChange={handleTabChange}
        />
        <ContentLayout
          emptyStateProps={getResponseTypeBasedEmptyProps(currentTab)}
          openResponsesModal={openResponsesModal}
          responsesData={responsesData}
          setResponsesData={setResponsesData}
          reactAdminProps={props}
          type={type}
          listingUuid={listingUuid}
          bookingUuid={bookingUuid}
        />
      </div>

      <ResponsesModal
        open={isResponsesModalOpen}
        onClose={closeResponsesModal}
        responsesData={responsesData}
      />
    </>
  );
};

export default withComponentLibraryTheme(ContentResponses);
