import React, { useState } from "react";

import {
  RESPONSES,
  columnConfig,
  contentResponsesFilterKeys,
  filterConfig,
  responseColumnKeys,
  tableChipsFormatter,
} from "../../data";

import useResponses from "./useResponses";

import ExlyTable from "common/Components/ExlyTable";
import {
  ExamScoringType,
  ResponsesActionField,
} from "../HelperComponents/HelperComponents";

import { useListController, useRefresh } from "react-admin";
import { SCORING_TYPES } from "schedule-v2/components/recorded-content/ExamCreation/modals/ScoringType/scoringType.data";
import EvaluateModal from "../EvaluateModal/EvaluateModal";
import styles from "./desktopDataLayout.module.css";

const DesktopDataLayout = (props) => {
  const { emptyStateProps, reactAdminProps, listingUuid } = props;

  const { type, handleShowResponses } = useResponses(props);
  const { filterValues } = useListController(reactAdminProps);
  const refetch = useRefresh();

  const [evaluateModalState, setEvaluateModalState] = useState({
    open: false,
    record: null,
  });

  const isAutoScoringFilterSelected =
    filterValues?.[contentResponsesFilterKeys.examScoringType] ===
    SCORING_TYPES.AUTOMATIC;
  const isExamTab = type === RESPONSES.EXAMS;

  const handleEvaluate = (record) => {
    setEvaluateModalState({ open: true, record });
  };

  const handleCloseEvaluate = () => {
    setEvaluateModalState({ open: false, record: null });
  };

  return (
    <div className={styles.table}>
      <ExlyTable
        ra_props={reactAdminProps}
        layoutProps={{
          showFixedBars: true,
        }}
        columnConfig={columnConfig({
          tab: type,
          handleShowResponses,
          handleEvaluate,
        })}
        tableFilters={
          isExamTab ? filterConfig({ isAutoScoringFilterSelected }) : undefined
        }
        tableChipsFormatter={tableChipsFormatter}
        customPrimaryAction={{
          renderCustomAction: (record) => (
            <div className="mr-2">
              <ResponsesActionField
                record={record}
                onShowResponses={handleShowResponses(record)}
                onEvaluate={() => handleEvaluate(record)}
                isExams={isExamTab}
              />
            </div>
          ),
        }}
        renderSecondaryAction={(record) => (
          <ExamScoringType
            record={record}
            source={responseColumnKeys.scoringType}
          />
        )}
        borderedFields
        fieldsLeftPadded
        drawerFieldsBordered
        fieldsAlignment="space-between"
        drawerFieldsAlignment="space-between"
        recordFooterVariant="secondary"
        noExportButton
        {...emptyStateProps}
      />

      <EvaluateModal
        onClose={handleCloseEvaluate}
        listingUuid={listingUuid}
        refetch={refetch}
        {...evaluateModalState}
      />
    </div>
  );
};

export default DesktopDataLayout;
