import React from "react";
import { is_empty } from "../../../utils/validations";
import style from "../../../Style.module.css";
import module_style from "./Style.module.css";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import { is_desktop } from "utils/Utils";

const EmptyView = (props) => {
  const {
    title,
    cta_title,
    width,
    height,
    preview_image,
    handleCta,
    hideTitle,
  } = props;
  const [showModal, set_showModal] = React.useState(false);
  return (
    <div style={{ width: `${width}`, height: `${height}` }}>
      {!hideTitle ? (
        <div
          className={`${module_style.empty_item} ${module_style.empty_title}`}
        >{`${title}`}</div>
      ) : (
        <></>
      )}

      {is_desktop ? (
        <div
          className={`${
            hideTitle ? module_style.empty_cta_live : module_style.empty_cta
          }`}
        >
          {!hideTitle && (
            <div
              onClick={() => {
                if (handleCta) handleCta();
              }}
              className={`${module_style.empty_item} ${style.save_cta} top_margin`}
            >{`${cta_title}`}</div>
          )}
          {!props.hideDemo ? (
            <div
              onClick={() => {
                if (!is_empty(preview_image)) {
                  set_showModal(true);
                }
              }}
              style={{ color: "#6c5dd3", marginTop: "5px" }}
              className={`${module_style.empty_item} top_margin pointer`}
            >{`Check Out Demo`}</div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <div
          className={`${style.flex_row} ${
            hideTitle ? module_style.empty_cta_live : module_style.empty_cta
          }`}
        >
          {!hideTitle ? (
            <div
              onClick={() => {
                if (handleCta) handleCta();
              }}
              className={`${style.save_cta} top_margin`}
              style={{ marginLeft: "10px" }}
            >{`${cta_title}`}</div>
          ) : (
            <></>
          )}
          {!props.hideDemo ? (
            <div
              onClick={() => {
                if (!is_empty(preview_image)) {
                  set_showModal(true);
                }
              }}
              style={{
                color: "#6c5dd3",
                marginTop: "5px",
                position: "absolute",
                right: "1.5rem",
              }}
              className={`top_margin pointer`}
            >{`Check Out Demo`}</div>
          ) : (
            <></>
          )}
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showModal}
        onClose={() => {
          set_showModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={`alert_modal ${module_style.checkout}`}>
          <span
            className={`${module_style.close}`}
            onClick={() => {
              set_showModal(false);
            }}
          >
            ✖
          </span>
          <img
            src={
              // @dev - update the logic of this component, move the assets from src to public
              window.screen.width > 780
                ? require(`../../../assets/images/${preview_image}`)
                : require(`../../../assets/images/mobile_preview/${preview_image}`)
            }
            alt="preview_image"
            className={module_style.preview_image}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EmptyView;
