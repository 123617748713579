import { useCallback, useEffect, useState } from "react";
import { useNotifications } from "utils/hooks";
import { fetchResponses } from "../../contentResponses.ApiCalls";
import { responseColumnKeys } from "../../data";
import { is_empty } from "utils/validations";

const useResponseData = ({ record, onError }) => {
  const { notify } = useNotifications();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFetchResponses = useCallback(async () => {
    setLoading(true);
    const {
      [responseColumnKeys.username]: username,
      [responseColumnKeys.questionnaireUid]: questionnaireUid,
      [responseColumnKeys.contentUid]: contentUid,
    } = record || {};
    try {
      const data = await fetchResponses({
        contentUid,
        questionnaireUid,
        username,
      });
      setData(data);
    } catch (error) {
      notify("Something went wrong while fetching responses.", "error");
      console.log(
        `Error while fetching responses for contentUid: ${contentUid} and questionnaireUid: ${questionnaireUid}`,
        error,
        "fetchResponses"
      );
      if (onError) onError(error);
    } finally {
      setLoading(false);
    }
  }, [record]);

  useEffect(() => {
    if (!is_empty(record)) handleFetchResponses();
  }, [record]);

  return { data, loading };
};

export default useResponseData;
