import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    responseRoot: {
      padding: "16px 0",
      borderBottom: "1px solid #E7E6E4",
      width: "100%",
    },

    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "8px",
    },

    responseLabel: {
      fontSize: "14px",
      lineHeight: "17px",
      color: theme?.palette?.secondary?.main,
      opacity: 0.85,
      fontWeight: 500,
    },

    responseValue: {
      fontSize: "12px",
      lineHeight: "14px",
      color: "#00000080",
    },

    responsesCta: {
      fontSize: "14px",
      lineHeight: "17px",
      color: theme?.palette?.primary?.main,
      padding: 0,
      textTransform: "none",
    },

    pagination: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
    },

    examsMobileHeaderRoot: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
    },

    scoringType: {
      fontSize: "12px",
      fontWeight: 500,
      padding: "2px 4px",
      borderRadius: "4px",
    },

    autoScoring: {
      color: "#7467CA",
      background:
        "linear-gradient(171deg, #E1DDF6 0%, #E5E2FA 42.71%, #E7E4FC 58.45%, #E1DDF6 100%)",
    },

    manualScoring: {
      color: "#D39300",
      background: "#FFF8E8",
    },

    statusFieldRoot: {
      display: "flex",
      alignItems: "center",
      gap: "5.82px",
      fontSize: "14px",
      "& svg": {
        fontSize: "17px",
        color: "#27252280",
      },
    },

    infoIcon: {
      width: "18px",
      cursor: "pointer",
    },

    actionTitle: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
      textDecoration: "underline",
      "& svg": {
        fontSize: "14.3px",
      },
    },
  }),
  { name: "CreatorTool" }
);
