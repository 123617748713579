import React, { useState } from "react";

import { InteractiveTooltip } from "@my-scoot/component-library-legacy";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

import DownArrow from "@material-ui/icons/ExpandMoreRounded";
import QuicIcon from "assets/vectors/recorded-content/mediaTypes/quiz.svg";
import { InfoOutlined } from "@material-ui/icons";
import ArrowIcon from "@material-ui/icons/ArrowForwardIosRounded";

import { Button, withStyles } from "@material-ui/core";
import { Pagination } from "common/Components/Pagination";
import { EXAM_RESPONSE_STATUS } from "../../ContentResponses.enum";
import { responseColumnKeys } from "../../data";
import { useStyles } from "./helperComponents.styles";

import classnames from "classnames";
import { SCORING_TYPES } from "schedule-v2/components/recorded-content/ExamCreation/modals/ScoringType/scoringType.data";
import { ActionField } from "ui/pages/CourseAnalytics/components/HelperComponents/HelperComponents";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";

const Accordion = withStyles({
  root: {
    width: "100%",
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    background: "#F3F4F6",
    color: theme?.palette?.secondary?.main,
    fontSize: "10px",
    lineHeight: "12px",
    fontWeight: 500,
    letterSpacing: "0.04em",
    minHeight: "38px",
    padding: "0 16px",
    "&$expanded": {
      minHeight: "38px",
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expandIcon: {
    padding: 0,
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);

export const DataAccordion = ({ children, title, ...restProps }) => {
  return (
    <Accordion square {...restProps}>
      <AccordionSummary expandIcon={<DownArrow />}>{title}</AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export const ResponseField = ({ label, value, showCta, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.responseRoot}>
      <div className={classes.title}>
        <div className={classes.responseLabel}>{label}</div>
        {showCta && (
          <Button
            role="button"
            className={classes.responsesCta}
            onClick={onClick}
          >
            See responses
          </Button>
        )}
      </div>
      <div className={classes.responseValue}>{value}</div>
    </div>
  );
};

export const StatusField = ({ record }) => {
  const classes = useStyles();

  const [openTooltip, setOpenTooltip] = useState(false);

  const status =
    record?.[responseColumnKeys.lastAttempt]?.[responseColumnKeys.status];
  const isAbandoned =
    record?.[responseColumnKeys.lastAttempt]?.[responseColumnKeys.isAbandoned];
  const isPassed = status === EXAM_RESPONSE_STATUS.PASSED;
  const isFailed = status === EXAM_RESPONSE_STATUS.FAILED;
  const isPendingEvaluation =
    status === EXAM_RESPONSE_STATUS.PENDING_EVALUATION;
  const showAbandonedInfo = isFailed && isAbandoned;

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  return (
    <span
      className={classnames(
        classes.statusFieldRoot,
        isPassed && "text-success",
        isFailed && "text-danger",
        isPendingEvaluation && "text-secondary"
      )}
    >
      {isPassed && "Passed"}
      {isFailed && "Failed"}
      {isPendingEvaluation && "Unmarked"}
      {showAbandonedInfo && (
        <InteractiveTooltip
          open={openTooltip}
          onCloseClick={handleCloseTooltip}
          onPrimaryBtnClick={handleCloseTooltip}
          totalSteps={0}
          primaryBtnText="Got it"
          title="This exam was abandoned by the student in the middle, hence no responses were saved. The student may retake and submit the exam for it to be evaluated."
        >
          <InfoOutlined
            onClick={handleOpenTooltip}
            classes={classes.infoIcon}
          />
        </InteractiveTooltip>
      )}
    </span>
  );
};

export const ListPagination = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.pagination}>
      <Pagination rowsPerPageOptions={[]} {...props} />
    </div>
  );
};

export const ExamsMobileHeader = ({ record, source }) => {
  const classes = useStyles();

  return (
    <div className={classes.examsMobileHeaderRoot}>
      <img src={QuicIcon} alt="quiz" />
      {record?.[source]}
    </div>
  );
};

export const ExamScoringType = ({ record, source }) => {
  const classes = useStyles();

  const scoringType = record[source];
  const isAutoScoring = scoringType === SCORING_TYPES.AUTOMATIC;

  return (
    <div
      className={classnames(
        classes.scoringType,
        isAutoScoring ? classes.autoScoring : classes.manualScoring
      )}
    >
      {isAutoScoring ? "Automatic grading" : "Manual grading"}
    </div>
  );
};

export const ResponsesActionField = ({
  record,
  isExams,
  onShowResponses,
  onEvaluate,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const classes = useStyles();

  const {
    submission: {
      [responseColumnKeys.questionsCount]: formQuestionsCount = 0,
    } = {},
    last_attempt: {
      [responseColumnKeys.status]: status,
      [responseColumnKeys.questionsCount]: examQuestionsCount = 0,
      [responseColumnKeys.isAbandoned]: isAbandoned,
    } = {},
    [responseColumnKeys.totalAttempts]: totalAttempts = 0,
    [responseColumnKeys.scoringType]: scoringType,
  } = record;
  const questionsCount = isExams ? examQuestionsCount : formQuestionsCount;
  const hasResponses = isExams ? totalAttempts !== 0 : questionsCount !== 0;
  const isManualScoringType = scoringType === SCORING_TYPES.MANUAL;
  const isPendingEvaluation =
    status === EXAM_RESPONSE_STATUS.PENDING_EVALUATION;
  const isFailed = status === EXAM_RESPONSE_STATUS.FAILED;
  const isEvaluateExam = isManualScoringType || isPendingEvaluation;
  const hideAction = isFailed && isAbandoned;

  if (hideAction) return null;

  return (
    <ActionField
      label={
        <div className={!isDesktop && classes.actionTitle}>
          {isEvaluateExam && isPendingEvaluation
            ? "Evaluate exam"
            : "See responses"}
          {!isDesktop && <ArrowIcon />}
        </div>
      }
      onClick={isEvaluateExam ? onEvaluate : onShowResponses}
      showCta={isEvaluateExam || hasResponses}
    />
  );
};
