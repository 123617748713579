import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { PUBLISH_EXAM_RESULT_APIS } from "./constants/PublishExamResults.apis";

export const publishAutomatedExamResult = async (payload) => {
  const { status, message } = await dataProvider.custom_request(
    PUBLISH_EXAM_RESULT_APIS.publish_results,
    apiMethods.POST,
    payload
  );

  if (!isRequestSuccessful(status))
    throw new Error(`Error publishing results: ${message} ${status}`);
};
