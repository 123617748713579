import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import useStyles from "../Codes.styles";
import { ReactComponent as CodeIcon } from "assets/vectors/code.svg";
import { customCodeLabels } from "../Codes.config";
import classnames from "classnames";
import EmptyListing from "common/Components/EmptyListing/EmptyListing";
import EmptyCodesIcon from "assets/vectors/empty-codes.svg";

const CodesHeaderComp = ({ isEmpty, onAddCode, isDesktop }) => {
  const classes = useStyles();
  return (
    <>
      {isDesktop && (
        <div className={classes.desktopHeader}>
          <div className={classes.desktopHeadLeftSection}>
            <CodeIcon className={classes.desktopIcon} />
            <div className="d-flex flex-column justify-content-center">
              <div className={classnames(classes.desktopTitle, "mb-1")}>
                {customCodeLabels.title}
              </div>
              {!isEmpty && (
                <div className={classnames(classes.font_16, "mt-2")}>
                  {customCodeLabels.description}
                </div>
              )}
            </div>
          </div>
          {!isEmpty && (
            <Button onClick={onAddCode} size="medium">
              Add Custom code
            </Button>
          )}
        </div>
      )}
      {!isDesktop && !isEmpty && (
        <div className={classes.mobileHeader}>
          <div className={classnames(classes.font_16, "weight_700", "mb-1")}>
            {customCodeLabels.title}
          </div>
          <div className={classnames(classes.font_14, "mt-2")}>
            {customCodeLabels.description}
          </div>
        </div>
      )}
    </>
  );
};

const MobileTitleComp = ({ isDesktop }) => {
  if (isDesktop) return;
  return (
    <div
      className={classnames(
        "d-flex justify-content-between align-items-center flex_grow" // TODO: see if this is actually needed?... or can we use `flex-grow-1` of bootstrap
      )}
    >
      <div>{customCodeLabels.title}</div>
      <CodeIcon className="mr-3" />
    </div>
  );
};

const EmptyStateComp = ({ isDesktop, onAddCode }) => {
  return (
    <EmptyListing
      content_title={customCodeLabels.title}
      logo={
        <img
          src={EmptyCodesIcon}
          alt="no codes"
          style={{
            width: isDesktop ? "216.23px" : "211.42px",
            height: isDesktop ? "180px" : "176px",
          }}
        />
      }
      primary_cta={{
        children: customCodeLabels.addButton,
        onClick: onAddCode,
      }}
    >
      {customCodeLabels.description}
    </EmptyListing>
  );
};

const CodesHeader = React.memo(CodesHeaderComp);
const MobileTitle = React.memo(MobileTitleComp);
const EmptyState = React.memo(EmptyStateComp);

export { CodesHeader, MobileTitle, EmptyState };
