import React from "react";
import { fetchStatusAndListingBasedSalesPgs } from "../Codes.api.helpers";
import { useFetchSalesPageTemplatesList } from "ui/pages/SalesPages/utils";
import { reorderListingsBasedOnStatus } from "../../../../features/Listings/utils/reorderListingsBasedOnStatus";
import { salesPgStatusToShow } from "../Codes.config";

const statusString = salesPgStatusToShow.join(",");

const getTemplateCoverImage = (templateUuid, templatesList) => {
  const { mobile_preview_image } = templatesList.find(
    (item) => item.uuid === templateUuid
  ) || { mobile_preview_image: "" };
  return mobile_preview_image;
};

export const useFetchCustomCodeSalesPgs = ({ listings }) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState();
  const [salesPgOptions, setSalesPgOptions] = React.useState([]);
  const { templatesList } = useFetchSalesPageTemplatesList();
  const listingsString = listings.join(",");

  React.useEffect(() => {
    setLoading(true);
    setError(false);
    fetchStatusAndListingBasedSalesPgs({
      status: statusString,
      listings: listingsString,
    })
      .then(({ sales_pages }) => {
        const listData = sales_pages.map((item) => ({
          ...item,
          cover_image:
            getTemplateCoverImage(item.template_uid, templatesList) || "",
          description: item.listing_title,
          label: item.title,
          value: item.uuid,
        }));
        setSalesPgOptions(() =>
          reorderListingsBasedOnStatus(listData, salesPgStatusToShow)
        );
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [templatesList, listings]);

  return {
    loading,
    error,
    salesPgOptions,
  };
};
