import React from "react";
import { Select } from "@my-scoot/component-library-legacy";
import styles from "./CustomCodeSelect.module.css";

export default function CustomCodeSelect(props) {
  const { input, meta, helperText, classes, ...rest } = props;
  return (
    <Select
      {...input}
      value={input?.value || (input?.multiple ? [] : "")}
      {...rest}
      classes={{ ...classes, contentWrap: styles.contentWrap }}
      error={meta?.error && meta?.touched}
      helperText={meta?.error && meta?.touched ? meta.error : helperText}
    />
  );
}
