import { dataProvider } from "data";
import { evaluate_exam_api } from "./evaluateModal.Api";

export const evaluateExam = async ({ attemptUid, payload }) => {
  const { data, status } = await dataProvider.custom_request(
    evaluate_exam_api.evaluate,
    "POST",
    {
      attempt_uid: attemptUid,
      evaluation_payload: payload,
    }
  );
  if (status !== 200)
    throw new Error(`Error evaluating exam: ${data} ${status}`);
  return data;
};

export const evaluateRank = async ({ listingUid, attemptUid, examMarks }) => {
  const { data, status } = await dataProvider.custom_request(
    evaluate_exam_api.evaluateRank,
    "GET",
    {
      listing_uid: listingUid,
      attempt_uid: attemptUid,
      exam_marks: examMarks,
    }
  );
  if (status !== 200)
    throw new Error(`Error evaluating customer rank: ${data} ${status}`);
  return data;
};

evaluateRank.responseKeys = {
  isRankUpdated: "is_rank_updated",
  oldRank: "old_rank",
  newRank: "new_rank",
};
