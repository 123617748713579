import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";

import {
  Button,
  Card,
  InteractiveTooltip,
} from "@my-scoot/component-library-legacy";
import { ExlyInput } from "common/form";
import { FormQuestion } from "schedule-v2/components/recorded-content/ExamCreation/pages/Questionnaire/HelperComponents/components/HelperComponents";

import DownloadIcon from "assets/vectors/download_with_bg.svg";

import { finalFormParsers } from "common/validate";
import { QUESTION_KEYS } from "schedule-v2/components/recorded-content/recordedContent.data";
import { pluralise } from "utils/Utils";
import { formKeys } from "../evaluateModal.data";
import { validators } from "../evaluateModal.utils";

import classnames from "classnames";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import styles from "./helperComponents.module.css";
import { FILE_TYPES } from "schedule-v2/components/recorded-content/ExamCreation/modals/QuestionsModal/pages/QuestionDetails/QuestionDetails.data";

const ScoreInput = ({ name, index, disabled }) => {
  return (
    <div className={styles.scoreInputRoot}>
      <div className={styles.scoreInputTitle}>Student gets:</div>
      <Field
        name={`${name}.${formKeys.marksReceived}`}
        component={ExlyInput}
        inputClassName={styles.scoreInput}
        size="thin"
        type="number"
        format={finalFormParsers.number}
        parse={finalFormParsers.number}
        validate={validators[formKeys.marksReceived](index)}
        disabled={disabled}
        classes={{
          notchedOutline: styles.notchedOutline,
        }}
        inputElementClassName="text-center"
        hideErrorText
      />
    </div>
  );
};

export const QuestionCard = ({
  question,
  index,
  name,
  disabled,
  isNoteShown,
  hideEvaluateMarksNote,
  onShowNote,
  onHideNote,
}) => {
  const {
    [QUESTION_KEYS.question]: questionLabel,
    [QUESTION_KEYS.totalMarks]: marks,
    [QUESTION_KEYS.answer]: answer,
    [QUESTION_KEYS.metadata]: metadata,
  } = question;
  const { [QUESTION_KEYS.metadataKeys.uploadType]: uploadType } =
    metadata || {};

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipStep, setTooltipStep] = useState(1);
  const [dontShowAgainTooltip, setDontShowAgainTooltip] = useState(false);

  const isImage = uploadType === FILE_TYPES.image;
  const showNote = !hideEvaluateMarksNote;
  const showDontShowAgainCheck = isNoteShown;
  const showDownloadCta = isImage && answer;

  const isFirstItem = index === 0;
  const isLastStep = tooltipStep === 2;
  const previewTooltip = showTooltip && isFirstItem;

  const handleOpenTooltip = () => {
    setShowTooltip(true);
  };

  const handleCloseTooltip = () => {
    setShowTooltip(false);
    if (!isNoteShown) onShowNote();
    if (dontShowAgainTooltip) onHideNote();
  };

  const handleNext = () => {
    if (isLastStep) {
      handleCloseTooltip();
      return;
    }
    setTooltipStep(tooltipStep + 1);
  };

  const tooltipTexts = {
    note1:
      "You can edit all the scores. Once the scores are finalised, you may publish them. We’ll notify the student that their results are out.",
    note2: (
      <div>
        <div className="mb-2">
          <strong>Note:</strong> Do make sure to re-verify the scores before
          publishing results, as the scores won’t be editable once published!
        </div>
        {showDontShowAgainCheck && (
          <ExlyCheckbox
            input={{
              onChange: () => {},
            }}
            meta={{}}
            id="evaludate_note"
            label="Don’t show this to me again"
            size="large"
            outerWrapperClassName="mb-2 text-nowrap"
            onChange={(checked) => setDontShowAgainTooltip(checked)}
            checkboxClassName={styles.checkboxClassName}
          />
        )}
      </div>
    ),
  };

  useEffect(() => {
    if (showNote)
      setTimeout(() => {
        handleOpenTooltip();
      }, 500);
  }, [showNote]);

  return (
    <Card shadow="shadow_md" className={styles.questionRoot}>
      {previewTooltip && <div className={styles.toolTipBackdrop}></div>}

      <div className={styles.questionTitle}>
        <div>
          Q{index + 1}. {questionLabel}
        </div>
        {showDownloadCta && (
          <a href={answer}>
            <img src={DownloadIcon} alt="download" height={32} width={32} />
          </a>
        )}
      </div>

      <div className={styles.questionBody}>
        <FormQuestion
          question={question}
          isResponse
          isExam
          isAutoScoring={false}
        />
      </div>

      <div
        className={classnames(
          styles.questionFooter,
          previewTooltip && styles.tooltipContainer
        )}
      >
        <div className={styles.questionMarks}>
          {marks} {pluralise("mark", marks)} question
        </div>
        <InteractiveTooltip
          open={previewTooltip}
          title={tooltipTexts[`note${tooltipStep}`]}
          primaryBtnText={isLastStep ? "Got it" : "Next"}
          onCloseClick={handleCloseTooltip}
          onPrimaryBtnClick={handleNext}
          totalSteps={0}
          enterDelay={0}
          leaveDelay={0}
          tooltipClasses={{
            popper: styles.interactiveToolTipPopper,
            tooltipPlacementBottom: styles.tooltipPlacementBottom,
          }}
        >
          <div>
            <ScoreInput name={name} index={index} disabled={disabled} />
          </div>
        </InteractiveTooltip>
      </div>
    </Card>
  );
};

export const ModalFooter = ({ score, showCta, onClick, primaryBtnProps }) => {
  return (
    <div>
      <div className={styles.modalFooterRoot}>
        <div>Total score: {score}</div>
        {showCta && (
          <Button onClick={onClick} {...primaryBtnProps}>
            Publish results
          </Button>
        )}
      </div>
    </div>
  );
};
