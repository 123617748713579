import React, { useEffect, Fragment, useMemo } from "react";
import { Field, useForm } from "react-final-form";
import classnames from "classnames";
import { composeValidators, is_empty } from "utils/validations";
import useStyles from "./styles";
import {
  ExlyDropdown,
  ExlyInput,
  ExlyOfferingSelect,
  ExlyTextarea,
} from "common/form";
import {
  code_postion_options,
  custom_code_form_field_names,
  custom_code_target,
  offering_specific_pages_options,
  target_dropdown_options,
} from "../../Codes.config";
import { isCustomCodeAllowed } from "common/validate";
import ExlyComplexDropdown from "common/form/ExlyComplexDropdown";
import { useFetchCustomCodeOfferings } from "../../hooks/useFetchCustomCodeOfferings";
import { useFetchCustomCodeSalesPgs } from "../../hooks/useFetchCustomCodeSalesPgs";
import { useFetchCustomCodeTyPgs } from "../../hooks/useFetchCustomCodeTyPgs";
import SalesPageOption from "../HelperComponents/SalesPageOption";
import EmptySalesPageView from "../HelperComponents/EmptySalesPageView";
import OfferingSpecificPageOption from "../HelperComponents/OfferingSpecificPageOption";
import { notification_color_keys, useNotifications } from "utils/hooks";
import CustomCodeSelect from "../HelperComponents/CustomCodeSelect";
import {
  getFilteredSalesPgsByListings,
  getFilteredThankyouPgs,
  handleShowOfferingSelector,
  handleShowSalesPgsSelector,
  handleShowSpecificPagesSelector,
} from "./utils";

export const required = (value) => {
  if (is_empty(value)) return "Required";
};

const AddCodeFormFields = ({ values, submitFailed, hasValidationError }) => {
  const classes = useStyles();
  const form = useForm();
  const { notify } = useNotifications();

  const {
    target,
    applicable_pages,
    metadata: { listings },
  } = values;

  const showOfferingSelector = useMemo(
    () => handleShowOfferingSelector(target),
    [target]
  );

  const showSpecificPagesSelector = useMemo(
    () => handleShowSpecificPagesSelector(listings),
    [listings]
  );

  const showSalesPagesSelector = useMemo(
    () => handleShowSalesPgsSelector(applicable_pages),
    [applicable_pages]
  );

  const { loading, offeringOptions, lastElementRef } =
    useFetchCustomCodeOfferings();
  const { loading: salesPgOptionsLoading, salesPgOptions } =
    useFetchCustomCodeSalesPgs({ listings });
  const { thankyouPgList } = useFetchCustomCodeTyPgs({ listings });

  useEffect(() => {
    if (submitFailed || hasValidationError)
      notify("Invalid Values", notification_color_keys.error);
  }, [submitFailed, hasValidationError]);

  useEffect(() => {
    const filteredThankyouPgs = getFilteredThankyouPgs(
      applicable_pages,
      thankyouPgList
    );
    form.change(
      custom_code_form_field_names.thankyou_pages,
      filteredThankyouPgs
    );
  }, [thankyouPgList, applicable_pages]);

  const handleTargetChange = (value) => {
    form.resetFieldState(custom_code_form_field_names.listings);
    form.resetFieldState(custom_code_form_field_names.applicable_pages);
    form.resetFieldState(custom_code_form_field_names.sales_pages);
    if (
      value === custom_code_target.HOME ||
      value === custom_code_target.ALL_PAGES_OF_ALL_OFFERINGS
    ) {
      form.change(custom_code_form_field_names.applicable_pages, []);
      form.change(custom_code_form_field_names.listings, []);
      form.change(custom_code_form_field_names.sales_pages, []);
      form.change(custom_code_form_field_names.thankyou_pages, []);
    }
  };

  const handleOfferingChange = (values) => {
    const filteredSalesPages = getFilteredSalesPgsByListings(
      values,
      salesPgOptions
    );
    form.change(custom_code_form_field_names.sales_pages, filteredSalesPages);
  };

  return (
    <div className={classes.contentWrap}>
      <Field
        name={custom_code_form_field_names.code_snippet}
        component={ExlyTextarea}
        label="Paste the custom code snippet here"
        placeholder="Enter the custom code snippet here"
        validate={composeValidators(required, isCustomCodeAllowed)}
        minWidth="100%"
        maxWidth="100%"
        customClasses={{ wrapper: classes.mb }}
      />
      <Field
        name={custom_code_form_field_names.name}
        label="Custom code name"
        validate={required}
        size="small"
        component={ExlyInput}
        fullWidth
        placeholder="Enter custom code name"
        wrapperClassName={classes.mb}
      />

      <div className={classnames(classes.mb, classes.pageSelectWrap)}>
        <div className={classnames(classes.heading, "mb-3")}>Add Code to</div>
        <Field
          name={custom_code_form_field_names.target}
          component={ExlyDropdown}
          fullWidth
          size="small"
          options={target_dropdown_options}
          placeholder="Select pages"
          formControlClassName="mt-1"
          validate={required}
          dropdownLabel={{
            label: "Select pages",
            size: "small",
            weight: "semi-bold",
          }}
          onChange={handleTargetChange}
        />
        <Field
          name={custom_code_form_field_names.listings}
          validate={(value, allValues) => {
            const { target } = allValues;
            const showOfferingSelector = handleShowOfferingSelector(target);
            if (showOfferingSelector && is_empty(value)) return "Required";
          }}
          component={showOfferingSelector ? ExlyOfferingSelect : Fragment}
          label="Select Offerings"
          multiple
          showSelectAll
          mobileModalTitle="Select Offerings"
          placeholder="Select Offerings"
          selectAllContent="Choose all from the list"
          deselectAllContent="Remove all from the list"
          options={offeringOptions}
          loading={loading}
          showStatus={true}
          onChange={handleOfferingChange}
          lastElementRef={lastElementRef}
          classes={{ wrapper: classes.mt }}
        />
        <Field
          name={custom_code_form_field_names.applicable_pages}
          component={showSpecificPagesSelector ? CustomCodeSelect : Fragment}
          multiple
          options={offering_specific_pages_options}
          label="Select offering-specific pages"
          mobileModalTitle="Select offering-specific pages"
          placeholder="Select offering-specific pages"
          renderOption={OfferingSpecificPageOption}
          validate={(value, allValues) => {
            const {
              metadata: { listings },
            } = allValues;
            const showSpecificPagesSelector =
              handleShowSpecificPagesSelector(listings);
            if (showSpecificPagesSelector && is_empty(value)) return "Required";
          }}
          classes={{ wrapper: classes.mt }}
        />
        <Field
          name={custom_code_form_field_names.sales_pages}
          component={
            showSalesPagesSelector && showOfferingSelector
              ? CustomCodeSelect
              : Fragment
          }
          fullWidth
          size="small"
          options={salesPgOptions}
          loading={salesPgOptionsLoading}
          showStatus={true}
          label="Select sales page(s)"
          mobileModalTitle="Select sales page(s)"
          placeholder="Select sales pages"
          multiple
          showChips
          showChipsCrossIcon
          renderOption={(renderProps) => (
            <SalesPageOption
              showCheckbox
              showStatus
              lastElementRef={lastElementRef}
              {...renderProps}
            />
          )}
          headerComponent={
            is_empty(salesPgOptions) &&
            !salesPgOptionsLoading && <EmptySalesPageView />
          }
          validate={showSalesPagesSelector && required}
          classes={{ wrapper: classes.mt }}
        />
      </div>

      <Field
        name={custom_code_form_field_names.code_position}
        component={ExlyComplexDropdown}
        fullWidth
        size="small"
        options={code_postion_options}
        placeholder="Place code in"
        checked
        validate={required}
        helperTextClassName={classes.errorTextWrap}
        dropdownLabel={{
          label: "Place code in",
          size: "small",
          weight: "semi-bold",
        }}
      />

      <div className={classes.alertWrap}>
        <span>
          <span className={classnames(classes.yelloish, "mr-2")}>Note*</span>-
          Adding third party code to your website’s head or body may affect its
          performance
        </span>
      </div>
    </div>
  );
};

export default AddCodeFormFields;
