import { maxValue, minValue, requiredWithMessage } from "common/validate";
import { composeValidators } from "utils/validations";
import { formKeys } from "./evaluateModal.data";

export const validators = {
  [formKeys.marksReceived]: (index) =>
    composeValidators(
      requiredWithMessage(`Question ${index + 1} marks required.`),
      minValue(0),
      (value, formValues) => {
        const { [formKeys.questions]: questions } = formValues;
        const question = questions[index];
        const { [formKeys.totalMarks]: totalMarks } = question;
        return maxValue(
          totalMarks,
          `Question ${index + 1} marks cannot be more than`
        )(value);
      }
    ),
};

export const getTotalMarks = (questions = [], source) =>
  questions?.reduce((sum, question) => sum + question[source], 0) || 0;

export const formatErrorMessage = (errors) =>
  errors?.[formKeys.questions]?.map(
    (error) => error && error[formKeys.marksReceived]
  );
